const Routes = {
    'accordionForm': 'accordionForm',
    'editArticle': 'editArticle',
    'editClassroom': 'editClassroom',
    'uploadFile': 'uploadFile',
    'editVideo(/:chapter)(/:section)': 'editVideo',
    // 'userEdit': 'userEdit',
    // 'userRole': 'userRole',
    // 'adminRole': 'adminRole',
    'seatsChange': 'seatsChange',
    'cancelStatistics': 'cancelStatistics',
    'studentOrderStatistics': 'studentOrderStatistics',
    'adminSeatsStatistics': 'adminSeatsStatistics',
    'Statistics/:StatisticsType': 'Statistics',
    'skillTree': 'skillTree',
    'chartsSignup': 'chartsSignup',
    'chartsRegister': 'chartsRegister',
    'totalRegistration': 'totalRegistration',
    // 'outputExcel': 'outputExcel',
    'configSkillTree': 'configSkillTree',
    'prevsTree': 'prevsTree',
    'videoReply': 'videoReply',
    'notification': 'notification',
    'classDataExcel': 'classDataExcel',
    'examConfig': 'examConfig',
    'classSeatsStats': 'classSeatsStats',
    // 'configAwards': 'configAwards',
    'bigCourse': 'bigCourse',
    'wechatCourse': 'wechatCourse',
    'learning': 'learning',
    'apList': 'apList',
    'ccList': 'ccList',
    'liveStudy': 'liveStudy',
    'subCourse/:id/:page': 'subCourse',
    'liveClassHeadmaster/:id/:subject': 'liveClassHeadmaster',
    'answerIssue': 'answerIssue',
    'chapterAchieve': 'chapterAchieve',
    'genCoupon': 'genCoupon',
    'vipUser(/:product_type)': 'vipUser',
    // 'editOffline(/:classId)': 'editOffline',
    'brushTopicsDatas': 'brushTopicsDatas',
    'offlineinfo': 'offlineinfo',
    'liveSignUp': 'liveSignUp',
    'liveStatistics': 'liveStatistics',
    'editRecruit': 'editRecruit',
    'editTeam': 'editTeam',
    'editContacts': 'editContacts',
    'uploadStudentInfo': 'uploadStudentInfo',
    'editTrainInfo': 'editTrainInfo',
    'campDataCenter': 'campDataCenter',
    'questionConfig': 'questionConfig',
    'editVirtualZone': 'editVirtualZone',
    'dormExchange': 'dormExchange',
    'examStatistics': 'examStatistics',
    'teamStatistics': 'teamStatistics',
    'classCenter': 'classCenter',
    'examRegainScore': 'changeExamMark',
    'contestQuery': 'contestQuery',
    'applyRefund': 'applyRefund',
    'manualRefund': 'manualRefund',
    'exceptionRefund': 'exceptionRefund',
    'reviewRefund': 'reviewRefund',
    'accountBalance': 'accountBalance',
    'liveRefundSetting': 'liveRefundSetting',
    'studentCheckin': 'studentCheckin',
    'deliveryAddress': 'deliveryAddress',
    'StockOut': 'StockOut',
    'ExpressList': 'ExpressList',
    'StockList': 'StockList',
    'exceptionExpress': 'exceptionExpress',
    // 'chat': 'chat',
    'feedback': 'feedback',
    'settings': 'settings',
    'groupManage': 'groupManage',
    'classNumberManage': 'classNumberManage',
    'teacherManage': 'teacherManage',
    'classManage(/:classId)': 'classManage',
    'classAdjust(/:status)': 'classAdjust',
    'placesManage': 'placesManage',
    'exchangeCoupon': 'exchangeCoupon',
    'quarkRefund': 'quarkRefund',
    'liveSlide': 'liveSlide',
    'salesPromotion': 'salesPromotion',
    'roleManagement': 'roleManagement',
    'permissionsConfig': 'permissionsConfig',
    'liveLabelsConfig': 'liveLabelsConfig',
    'editCampAttentions': 'editCampAttentions',
    'dashboard': 'dashboard',
    'editSemesterOld': 'editSemesterOld',
    'specialCareMembers': 'specialCareMembers',
    'homePage': 'homePage',
    '': 'homePage',
    'userInfoAudit': 'userInfoAudit',
    'bulletinBoard': 'bulletinBoard',
    'financialOrderDownload': 'financialOrderDownload',
    'uploadVideo(/:class_id)': 'uploadVideo',
    'campRefundAndPay': 'campRefundAndPay',
    ':whatever': 'notFound',
};

const classSeatsStats = () => import(/* webpackChunkName: "ClassSeatsStats" */ 'admin/controllers/ClassSeatsStatsController');
const classDataExcel = () => import(/* webpackChunkName: "ClassDataExcel" */ 'admin/controllers/ClassDataExcelController');
const uploadStudentInfo = () => import(/* webpackChunkName: "UploadStudentInfo" */ 'admin/controllers/UploadStudentInfoController');
const editVirtualZone = () => import(/* webpackChunkName: "EditVirtualZoneController" */ 'admin/controllers/EditVirtualZoneController');
const examConfig = () => import(/* webpackChunkName: "ExamConfigController" */ 'admin/controllers/ExamConfigController');
const notification = () => import(/* webpackChunkName: "NotificationController" */ 'admin/controllers/NotificationController');
const videoReply = () => import(/* webpackChunkName: "VideoReplyController" */ 'admin/controllers/VideoReplyController');
const prevsTree = () => import(/* webpackChunkName: "PrevsTreeController" */ 'admin/controllers/PrevsTreeController');
const configSkillTree = () => import(/* webpackChunkName: "ConfigSkillTreeController" */ 'admin/controllers/ConfigSkillTreeController');
// const outputExcel = () => import(/* webpackChunkName: "OutputExcelController" */ 'admin/controllers/OutputExcelController');
const totalRegistration = () => import(/* webpackChunkName: "TotalRegistrationController" */ 'admin/controllers/TotalRegistrationController');
const chartsRegister = () => import(/* webpackChunkName: "ChartsRegisterController" */ 'admin/controllers/ChartsRegisterController');
const chartsSignup = () => import(/* webpackChunkName: "ChartsSignupController" */ 'admin/controllers/ChartsSignupController');
// const userRole = () => import(/* webpackChunkName: "UserRoleController" */ 'admin/controllers/UserRoleController');
// const adminRole = () => import(/* webpackChunkName: "AdminRoleController" */ 'admin/controllers/AdminRoleController');
const editArticle = () => import(/* webpackChunkName: "EditArticleController" */ 'admin/controllers/EditArticleController');
const accordionForm = () => import(/* webpackChunkName: "AccordionFormController" */ 'admin/controllers/AccordionFormController');
const editClassroom = () => import(/* webpackChunkName: "EditClassroomController" */ 'admin/controllers/EditClassroomController');
const uploadFile = () => import(/* webpackChunkName: "UploadFileController" */ 'admin/controllers/UploadFileController');
const editVideo = (chapter, section) => import(/* webpackChunkName: "EditVideoController" */ 'admin/controllers/EditVideoController');
// const userEdit = () => import(/* webpackChunkName: "UserEditController" */ 'admin/controllers/UserEditController');
const seatsChange = () => import(/* webpackChunkName: "SeatChangeController" */ 'admin/controllers/SeatChangeController');
const cancelStatistics = () => import(/* webpackChunkName: "CancelStatisticsController" */ 'admin/controllers/CancelStatisticsController');
const studentOrderStatistics = () => import(/* webpackChunkName: "StudentOrderStatisticsController" */ 'admin/controllers/StudentOrderStatisticsController');
const adminSeatsStatistics = () => import(/* webpackChunkName: "AdminSeatsStatisticsController" */ 'admin/controllers/AdminSeatsStatisticsController');
const occupySeats = () => import(/* webpackChunkName: "OccupySeatsController" */ 'admin/controllers/OccupySeatsController');
const skillTree = () => import(/* webpackChunkName: "EditTreeController" */ 'admin/controllers/EditTreeController');
// const configAwards = () => import(/* webpackChunkName: "ConfigAwardsController" */ 'admin/controllers/ConfigAwardsController');
const bigCourse = () => import(/* webpackChunkName: "BigCourseController" */ 'admin/controllers/BigCourseController');
const wechatCourse = () => import(/* webpackChunkName: "WechatCourseController" */ 'admin/controllers/WechatCourseController');
const learning = () => import(/* webpackChunkName: "WechatLearningController" */ 'admin/controllers/WechatLearningController');
const apList = () => import(/* webpackChunkName: "ApListController" */ 'admin/controllers/ApListController');
const ccList = () => import(/* webpackChunkName: "CcListController" */ 'admin/controllers/CcListController');
const liveStudy = () => import(/* webpackChunkName: "liveStudyController" */ 'admin/controllers/liveStudyController');
const subCourse = () => import(/* webpackChunkName: "SubCourseController" */ 'admin/controllers/SubCourseController');
const liveClassHeadmaster = () => import(/* webpackChunkName: "HeadmasterController" */ 'admin/controllers/HeadmasterController');
const answerIssue = () => import(/* webpackChunkName: "AnswerIssueController" */ 'admin/controllers/AnswerIssueController');
const chapterAchieve = () => import(/* webpackChunkName: "ChapterAchieveController" */ 'admin/controllers/ChapterAchieveController');
const genCoupon = () => import(/* webpackChunkName: "GenCouponController" */ 'admin/controllers/GenCouponController');
const vipUser = (productType) => import(/* webpackChunkName: "VipUserController" */ 'admin/controllers/VipUserController');
// const editOffline = (classId) => import(/* webpackChunkName: "EditOfflineController" */ 'admin/controllers/EditOfflineController');
const brushTopicsDatas = () => import(/* webpackChunkName: "BrushTopicsDatasController" */ 'admin/controllers/BrushTopicsDatasController');
const offlineinfo = () => import(/* webpackChunkName: "OfflineinfoController" */ 'admin/controllers/OfflineinfoController');
const liveSignUp = () => import(/* webpackChunkName: "liveSignUpController" */ 'admin/controllers/liveSignUpController');
const liveStatistics = () => import(/* webpackChunkName: "LiveStatisticsController" */ 'admin/controllers/LiveStatisticsController');
const editRecruit = () => import(/* webpackChunkName: "EditRecruitController" */ 'admin/controllers/EditRecruitController');
const liveSlide = () => import(/* webpackChunkName: "LiveSlideController" */ 'admin/controllers/LiveSlideController');
const editTeam = () => import(/* webpackChunkName: "EditTeamController" */ 'admin/controllers/EditTeamController');
const editContacts = () => import(/* webpackChunkName: "EditContactsController" */ 'admin/controllers/EditContactsController');
const editTrainInfo = () => import(/* webpackChunkName: "EditTrainInfoController" */ 'admin/controllers/EditTrainInfoController');
const campDataCenter = () => import(/* webpackChunkName: "CampDataCenterController" */ 'admin/controllers/CampDataCenterController');
const dormExchange = () => import(/* webpackChunkName: "DormExchange" */ 'admin/controllers/DormExchangeController');
const questionConfig = () => import(/* webpackChunkName: "QuestionConfig" */ 'admin/controllers/QuestionConfigController');
const examStatistics = () => import(/* webpackChunkName: "ExamStatistics" */ 'admin/controllers/ExamStatisticsController');
const teamStatistics = () => import(/* webpackChunkName: "TeamStatistics" */ 'admin/controllers/TeamStatisticsController');
const classCenter = () => import(/* webpackChunkName: "ClassCenter" */ 'admin/controllers/ClassCenterController');
const changeExamMark = () => import(/* webpackChunkName: "ChangeExamMark" */ 'admin/controllers/ChangeExamMarkController');
const contestQuery = () => import(/* webpackChunkName: "ContestQuery" */ 'admin/controllers/ContestQueryController');
const applyRefund = () => import(/* webpackChunkName: "ApplyRefund" */ 'admin/controllers/ApplyRefundController');
const manualRefund = () => import(/* webpackChunkName: "ManualRefund" */ 'admin/controllers/ManualRefundController');
const exceptionRefund = () => import(/* webpackChunkName: "ExceptionRefund" */ 'admin/controllers/ExceptionRefundController');
const reviewRefund = () => import(/* webpackChunkName: "ReviewRefund" */ 'admin/controllers/ReviewRefundController');
const accountBalance = () => import(/* webpackChunkName: "AccountBalance" */ 'admin/controllers/AccountBalanceController');
const liveRefundSetting = () => import(/* webpackChunkName: "LiveRefundSetting" */ 'admin/controllers/LiveRefundSettingController');
// const chat = () => import(/* webpackChunkName: "Chat" */ 'admin/controllers/ChatController');
const studentCheckin = () => import(/* webpackChunkName: "StudentCheckin" */ 'admin/controllers/StudentCheckinController');
const deliveryAddress = () => import(/* webpackChunkName: "DeliveryAddress" */ 'admin/controllers/deliveryAddressController');
const StockOut = () => import(/* webpackChunkName: "StockOut" */ 'admin/controllers/StockOutController');
const ExpressList = () => import(/* webpackChunkName: "ExpressList" */ 'admin/controllers/ExpressListController');
const StockList = () => import(/* webpackChunkName: "StockList" */ 'admin/controllers/StockListController');
const exceptionExpress = () => import(/* webpackChunkName: "ExceptionExpress" */ 'admin/controllers/ExceptionExpressController');
const feedback = () => import(/* webpackChunkName: "Feedback" */ 'admin/controllers/FeedbackController');
const settings = () => import(/* webpackChunkName: "Settings" */ 'admin/controllers/SettingsController');
const groupManage = () => import(/* webpackChunkName: "GroupManage" */ 'admin/controllers/GroupManageController');
const classNumberManage = () => import(/* webpackChunkName: "ClassNumberManage" */ 'admin/controllers/ClassNumberController');
const classManage = (classId) => import(/* webpackChunkName: "ClassManage" */ 'admin/controllers/ClassManageController');
const classAdjust = () => import(/* webpackChunkName: "ClassAdjust" */ 'admin/controllers/ClassAdjustController');
const teacherManage = () => import(/* webpackChunkName: "TeacherManage" */ 'admin/controllers/TeacherController');
const placesManage = () => import(/* webpackChunkName: "PlacesManage" */ 'admin/controllers/PlacesController');
const exchangeCoupon = () => import(/* webpackChunkName: "ExchangeCoupon" */ 'admin/controllers/ExchangeCouponController');
const quarkRefund = () => import(/* webpackChunkName: "QuarkRefund" */ 'admin/controllers/QuarkRefundController');
const salesPromotion = () => import(/* webpackChunkName: "SalesPromotion" */ 'admin/controllers/SalesPromotionController');
const roleManagement = () => import(/* webpackChunkName: "RoleManagement" */ 'admin/controllers/RoleManagementController');
const permissionsConfig = () => import(/* webpackChunkName: "PermissionsConfig" */ 'admin/controllers/PermissionsConfigController');
const financialOrderDownload = () => import(/* webpackChunkName: "FinancialOrderDownload" */ 'admin/controllers/FinancialOrderDownloadController');
const liveLabelsConfig = () => import(/* webpackChunkName: "LiveLabelsConfig" */ 'admin/controllers/LiveLabelsConfigController');
const editCampAttentions = () => import(/* webpackChunkName: "EditCampAttentions" */ 'admin/controllers/EditCampAttentionslController');
const dashboard = () => import(/* webpackChunkName: "Dashboard" */ 'admin/controllers/DashboardController');
const editSemesterOld = () => import(/* webpackChunkName: "SemesterController" */ 'admin/controllers/SemesterController');
const specialCareMembers = () => import(/* webpackChunkName: "SpecialCareMembers" */ 'admin/controllers/SpecialCareMembersController');
const userInfoAudit = () => import(/* webpackChunkName: "UserInfoAudit" */ 'admin/controllers/UserInfoAuditController');
const bulletinBoard = () => import(/* webpackChunkName: "BulletinBoard" */ 'admin/controllers/BulletinBoardController');
const homePage = () => import(/* webpackChunkName: "HomePageController" */ 'admin/controllers/HomePageController');
const uploadVideo = () => import(/* webpackChunkName: "UploadVideoController" */ 'admin/controllers/UploadVideoController');
const campRefundAndPay = () => import(/* webpackChunkName: "UploadVideoController" */ 'admin/controllers/CampcheckFinanceController');

let dyModules = {
    changeExamMark,
    classManage,
    accordionForm,
    editArticle,
    editClassroom,
    uploadFile,
    editVideo,
    // userEdit,
    // userRole,
    // adminRole,
    seatsChange,
    cancelStatistics,
    studentOrderStatistics,
    adminSeatsStatistics,
    skillTree,
    chartsSignup,
    chartsRegister,
    totalRegistration,
    // outputExcel,
    configSkillTree,
    prevsTree,
    videoReply,
    notification,
    classDataExcel,
    examConfig,
    classSeatsStats,
    // configAwards,
    bigCourse,
    wechatCourse,
    learning,
    apList,
    ccList,
    liveStudy,
    subCourse,
    liveClassHeadmaster,
    answerIssue,
    chapterAchieve,
    genCoupon,
    vipUser,
    // editOffline,
    brushTopicsDatas,
    offlineinfo,
    liveSignUp,
    liveStatistics,
    editRecruit,
    editTeam,
    editContacts,
    uploadStudentInfo,
    editTrainInfo,
    campDataCenter,
    questionConfig,
    editVirtualZone,
    dormExchange,
    examStatistics,
    teamStatistics,
    classCenter,
    examRegainScore: changeExamMark,
    contestQuery,
    applyRefund,
    manualRefund,
    exceptionRefund,
    reviewRefund,
    accountBalance,
    liveRefundSetting,
    studentCheckin,
    deliveryAddress,
    StockOut,
    ExpressList,
    StockList,
    exceptionExpress,
    // chat,
    feedback,
    settings,
    occupySeats,
    groupManage,
    classNumberManage,
    teacherManage,
    classAdjust,
    placesManage,
    exchangeCoupon,
    quarkRefund,
    liveSlide,
    salesPromotion,
    roleManagement,
    permissionsConfig,
    liveLabelsConfig,
    editCampAttentions,
    dashboard,
    editSemesterOld,
    specialCareMembers,
    homePage,
    userInfoAudit,
    bulletinBoard,
    financialOrderDownload,
    uploadVideo,
    campRefundAndPay,
};
export {
    dyModules,
};

let RoutesFunc = {
    // login: function () {
    //     // test not amd components
    //     require('@/container/login');
    // },
};

// routeName 和 export 的模块名字需要一致
Object.keys(dyModules).forEach(routeName => {
    if (routeName === '') {
        return false;
    }
    const callback = dyModules[routeName];
    RoutesFunc[routeName] = function () {
        if (callback) {
            $('#primary_content').removeClass('hide').siblings('#vue_primary_content').addClass('hide');
            const res = callback();
            res.then(controller => {
                controller.default(...arguments);
            });
        }
    };
});
Routes['login'] = 'notFound';
RoutesFunc['notFound'] = () => {
    $('#vue_primary_content').removeClass('hide').siblings('#primary_content').addClass('hide');
};
export {
    RoutesFunc,
    Routes,
};
