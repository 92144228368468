<!-- 用法 除了model，formItemList， ref属性，其他的所有属性都会被当作form表单的属性
        <FormEngine
            :model="form"
            :formItemList="formItemList"
            ref="filterForm"
            ...>
            <template v-slot:button>
                <el-button size="small" type="primary" @click="handlesearch">搜索</el-button>
                <el-button size="small" @click="reset">重置</el-button>
            </template>
        </FormEngine>
-->
<template>
    <div>
        <el-form
            class="form-engine"
            :model="model"
            ref="formRef"
            v-bind="{...defaultAttrs, ...$attrs}"
            @validate="handlerValidate">
            <el-form-item
                v-for="(item, index) in formItemList"
                :key="index"
                :prop="item.field"
                v-bind="item.formItemAttrs">
                <component
                    :is="item.compName"
                    v-model="model[item.field]"
                    v-bind="item.compAttrs || {}"
                    v-on="item.compEvents || {}">
                </component>
                <slot :name="item.field"></slot>
            </el-form-item>
            <el-form-item>
                <slot name="button"></slot>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

import Select from './components/Select.vue';
import Date from './components/Date.vue';
import CheckBox from './components/CheckBox.vue';
import CheckBoxGroup from './components/CheckBoxGroup.vue';
import Input from './components/Input.vue';
import ImageUpload from '@/components/image-upload/Main.vue';
import EditorSummernote from '@/components/editor-summernote/Index.vue';
import EditorQuill from '@/components/editor-quill/Index.vue';
import Upload from './components/Upload.vue';

export default {
    components: {
        Input,
        Select,
        Date,
        CheckBox,
        CheckBoxGroup,
        ImageUpload,
        EditorSummernote,
        EditorQuill,
        Upload,
    },
    props: {
        // formItemList: [{
        //     field: 'xxx', // 字段
        //     compName: 'Input', // 组件名
        //     formItemAttrs: { ... 表单el-form-item 的属性  },
        //     compAttrs: { ... 组件的属性 },
        //     compEvents：{ ... 组件的事件 }
        // }]
        formItemList: {
            type: Array,
            required: true,
            default: () => [],
        },
        model: {
            default: () => {},
            type: Object,
        },
    },
    data () {
        return {
            form: {},
            defaultAttrs: {
                size: 'medium',
            },
        };
    },
    mounted () {
        // console.log(this.$attrs);
    },
    methods: {
        handlerValidate (prop, res, errmessage) {
            this.$emit('validate', prop, res, errmessage);
        },
        validate (callback) {
            this.$refs['formRef'].validate(callback);
        },
        validateField (props, callback) {
            this.$refs['formRef'].validateField(props, callback);
        },
        resetFields () {
            this.$refs['formRef'].resetFields();
        },
        clearValidate (props) {
            this.$refs['formRef'].clearValidate(props);
        },
    },
};
</script>

<style lang="scss" scoped>
.form-engine {
}
</style>
