const STATIC_CDN_URL = process.env.STATIC_CDN_URL;
const ZHIXIN_URL = process.env.ZHIXIN_URL;
const VERSION = process.env.VERSION;
const DOMAIN = process.env.DOMAIN;
const ZT_DOMAIN = process.env.ZT_DOMAIN;
const LIVEAPI_URL = process.env.LIVEAPI_URL;
// 每个角色都有 1：普通 2：高级 3：超级三种级别
const ROLES = {
    '1': '管理员',
    '2': '线下市场',
    '3': '线上市场',
    '4': '助教',
    '5': '教师',
    '6': '财务',
    '7': '班级组',
    '8': 'AP',
    '9': '呼叫',
    '10': 'CC',
    '11': '运营',
};
export {
    STATIC_CDN_URL,
    ZHIXIN_URL,
    VERSION,
    DOMAIN,
    ROLES,
    ZT_DOMAIN,
    LIVEAPI_URL,
};

export const UnionType = {
    99: '联报活动',
    1: '多学科联报',
    2: '暑秋联报',
    3: '寒春联报',
    4: '秋寒春联报',
    5: '春暑秋联报',
    6: '多学科暑秋联报',
    7: '多学科寒春联报',
    8: '多学科秋寒春联报',
    9: '多学科春暑秋联报',
};

export const PackageType = {
    BULLET: 1,
    PUSH: 2,
};

export const ROUND_TYPE = [
    '新手轮',
    '新手刷题',
    '竞赛一轮',
    '竞赛二轮',
    '竞赛三轮',
    '基础轮',
    '强基轮',
    '冲刺轮',
    '衔接轮',
    '竞赛一轮经典版',
    '竞赛一轮加强版',
    '竞赛二轮经典版',
    '竞赛二轮加强版',
    '预备轮',
    '先导课',
    '新手轮国际版',
    '预备轮国际版',
    '新领军轮',
];

export const SUBJECT_TYPE = {
    'BIO': '生物',
    'CHEM': '化学',
    'ENG': '英语',
    'MATH': '数学',
    'PHY': '物理',
};
export const GRADE_TYPE = {
    'high': '高中',
    'middle': '初中',
    'primary': '小学',
};
