require('@babel/polyfill');
window.APP = window.APP || {};
window.$ = window.jQuery = require('jquery');
require('./css');
require('./api/ajax-prefilter');

$.ajaxSetup({
    complete: (data, status) => {
        if (status === 'success' && data.responseJSON && data.responseJSON.msg === 'NotLogin') {
            if (window.location.href.includes('return_url') || window.location.hash.includes('#login') || !window.location.hash) {
                return false;
            }
            if (window.location.hash.includes('#login') || !window.location.hash) {
                window.location.href = window.location.origin + `/#login`;
                return false;
            }
            window.location.href = window.location.origin + `/#login?return_url=${encodeURIComponent(window.location.href)}`;
            return false;
        }
    },
});

define([
    './router',
    './util/util',
    './sidebar',
    './vue-index',
    'formvalidation',
    'bootstrapfv',
    'bootstrap',
    'slimScroll',
    'dataTable',
    'sweetalert',
], function (router, Util, VmTop, VmContent) {
    $(function () {
        $(document).ajaxError(
            // 所有ajax请求异常的统一处理函数，处理
            function (event, xhr) {
                const hash = location.hash.split('?')[0].slice(1);
                if (xhr.status === 'undefined') {
                    return;
                } else if (hash === 'login') {
                    return;
                } else if (hash === 'landingPageConfigure') {
                    return;
                } else if (hash === 'landingPagePcConfigure') {
                    return;
                } else if (hash === 'appLandingPageConfigure') {
                    return;
                } else if (hash === 'addEntranceCourseConfigure') {
                    return;
                }
                switch (xhr.status) {
                case 404:
                    window.alert('您没有权限，请联系质心管理员');
                    break;
                }
            },
        );
        $(document).on('hidden.bs.modal', '.modal', function () {
            if ($('.modal-backdrop.in').length) {
                $('body').addClass('modal-open');
            }
        });
        if (new Date().getMonth() === 11) {
            $('body').addClass('christmas-cursor');
        }
    });
    VmContent.vm.VmTop = VmTop;
    window.vm = VmContent.vm;

    APP.addView = function (view) {
        if (this.view) {
            if (this.view.close) {
                this.view.close();
            }
            this.view.remove();
        }
        if (view) {
            this.view = view;
            $('#primary_content').html(this.view.el);
        }
    };
    APP.util = new Util();
    APP.dataTableOption = {
        'order': [
            [0, 'desc'],
        ],
        paging: true,
        searching: true,
        'pageLength': 100,
        'lengthMenu': [12, 15, 20, 30, 50, 100],
        'language': {
            'paginate': {
                'first': '第1页',
                'previous': '上一页',
                'next': '下一页',
                'last': '最后',
            },
        },
        'bStateSave': true,
    };
    var originAlert = window.alert;
    APP.alert = window.alert = function (data, callback) {
        if (!APP.IE) {
            window.swal(data, callback);
        } else {
            if (callback) {
                if (data.type === 'input') {
                    callback(window.prompt(data.title + '\n' + (data.text || ''), (data.inputPlaceholder || '')));
                } else {
                    callback(window.confirm(data.title + '\n' + (data.text || '服务器错误')));
                }
            } else {
                originAlert(data.title + '\n' + (data.text || '服务器错误'));
            }
        }
    };
    APP.convertTime = function (second, len) {
        if (second === 0) {
            return false;
        } else {
            var seconds = Number(second) * 1000 + 28800000;
            var date = new Date(seconds);
            return date.toISOString().slice(0, len || 10).replace(/T/, ' ');
        }
    };
    APP.convertDate = function (date) {
        if (date === '无' || date === '') {
            return 0;
        } else {
            var seconds = new Date();
            var yyyy = date.slice(0, 4);
            var mm = date.slice(5, 7);
            var dd = date.slice(8, 10);
            var hh = date.slice(11, 13);
            var ii = date.slice(14, 16);
            var ss = date.slice(17, 19);
            seconds.setFullYear(yyyy, mm - 1, dd);
            seconds.setHours(hh);
            seconds.setMinutes(ii);
            seconds.setSeconds(ss);
            seconds = parseInt(seconds.getTime() / 100000) * 100;
            return seconds;
        }
    };
    APP.regexps = {
        ID: /^[1-9]{1}[0-9]{5}(18|19|20){1}\d{2}((0[1-9])|(1[0-2]))((0[1-9]|[12]\d)|3[01])\d{3}(\d|X)$/i,
    };
    APP.validateIDNumber = function (IDNumber, success, fail) {
        var map = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
        var lastNum = IDNumber.slice(-1);
        var sum = 0;
        var param = 0;
        var isRight = false;
        for (var i = 17; i > 0; i--) {
            param = Math.pow(2, i) % 11;
            sum += param * IDNumber[17 - i];
        }
        isRight = map[sum % 11].toString() === lastNum.toString();

        if (isRight) {
            if (success) {
                success();
            }
        } else {
            if (fail) {
                fail();
            }
        }
        return isRight;
    };
    APP.$msgDialog = (title, msg, callback = '') => {
        VmContent.vm.$confirm(msg, title, {
            center: true,
            showCancelButton: false,
            confirmButtonText: '好的',
            confirmButtonClass: 'msg-btn',
        }).then(() => {
            callback && callback();
        }).catch(() => {
            callback && callback();
        });
    };
    APP.$showDialog = (title, msg, callback = '') => {
        VmContent.vm.$confirm(msg, title, {
            center: true,
            confirmButtonText: '确认',
            confirmButtonClass: 'msg-btn',
            cancelButtonClass: 'msg-btn',
        }).then(() => {
            callback && callback();
        }).catch(() => {
        });
    };
    router.initialize();
});
