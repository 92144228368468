<template>
    <aside class="control-sidebar control-sidebar-dark">
        <ul class="nav nav-tabs nav-justified control-sidebar-tabs">
            <li class="active"><a href="#control-sidebar-theme-demo-options-tab" data-toggle="tab"><i
                class="fa fa-wrench"></i></a></li>
        </ul>
        <div class="tab-content">
            <div id="control-sidebar-theme-demo-options-tab" class="tab-pane active">
                <div><h4 class="control-sidebar-heading">Layout Options</h4>
                    <!--<div class="form-group"><label class="control-sidebar-subheading">-->
                        <!--<input type="checkbox"-->
                               <!--data-layout="fixed"-->
                               <!--class="pull-right"-->
                               <!--checked="checked"> Fixed-->
                        <!--layout</label>-->
                        <!--<p>Activate the fixed layout. You can't use fixed and boxed layouts together</p></div>-->
                    <!--<div class="form-group"><label class="control-sidebar-subheading">-->
                        <!--<input type="checkbox"-->
                               <!--data-layout="layout-boxed"-->
                               <!--class="pull-right"> Boxed-->
                        <!--Layout</label>-->
                        <!--<p>Activate the boxed layout</p></div>-->
                    <!--<div class="form-group"><label class="control-sidebar-subheading">-->
                        <!--<input type="checkbox"-->
                               <!--data-layout="sidebar-collapse"-->
                               <!--class="pull-right"> Toggle-->
                        <!--Sidebar</label>-->
                        <!--<p>Toggle the left sidebar's state (open or collapse)</p></div>-->
                    <div class="form-group">
                        <label class="control-sidebar-subheading">
                        <input type="checkbox"
                               v-model="collapse"
                               class="pull-right"> Sidebar Expand on Hover</label>
                        <p>Let the sidebar mini expand on hover</p></div>
                    <!--<div class="form-group"><label class="control-sidebar-subheading">-->
                        <!--<input type="checkbox"-->
                               <!--data-controlsidebar="control-sidebar-open"-->
                               <!--class="pull-right"> Toggle-->
                        <!--Right Sidebar Slide</label>-->
                        <!--<p>Toggle between slide over content and push content effects</p></div>-->
                    <!--<div class="form-group"><label class="control-sidebar-subheading">-->
                        <!--<input type="checkbox"-->
                               <!--data-sidebarskin="toggle"-->
                               <!--class="pull-right"> Toggle-->
                        <!--Right Sidebar Skin</label>-->
                        <!--<p>Toggle between dark and light skins for the right sidebar</p></div>-->
                    <h4 class="control-sidebar-heading">Skins</h4>
                    <ul class="list-unstyled clearfix">
                        <li v-for="(item, i) in skinkList" @click="changeSkin(item.name)" :key="i">
                            <a href="javascript:void(0);" class="clearfix">
                                <div>
                                    <span class="mini-t-l" :style="'background-color: '+item.data[0]"></span>
                                    <span class="mini-t-r" :style="'background-color: '+item.data[0]"></span>
                                </div>
                                <div>
                                    <span class="mini-c-l" :style="'background-color: '+item.data[1]"></span>
                                    <span class="mini-c-r"></span>
                                </div>
                            </a>
                            <p class="text-center no-margin">{{ item.name }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </aside>
</template>
<script>
export default {
    name: 'control-sidebar',
    data () {
        const collapseSidebar = window.localStorage.getItem('sidebar-collapse') === 'true';
        return {
            collapse: collapseSidebar,
            skinkList: [
                { name: 'blue', data: ['#367fa9', '#222d32'] },
                { name: 'black', data: ['#fefefe', '#222'] },
                { name: 'purple', data: ['#605ca8', '#222d32'] },
                { name: 'green', data: ['#008d4c', '#222d32'] },
                { name: 'red', data: ['#dd4b39', '#222d32'] },
                { name: 'yellow', data: ['#db8b0b', '#222d32'] },
                { name: 'blue light', data: ['#367fa9', '#f9fafc'] },
                { name: 'black light', data: ['#fefefe', '#f9fafc'] },
                { name: 'purple light', data: ['#605ca8', '#f9fafc'] },
                { name: 'green light', data: ['#008d4c', '#f9fafc'] },
                { name: 'red light', data: ['#dd4b39', '#f9fafc'] },
                { name: 'yellow light', data: ['#db8b0b', '#f9fafc'] },
            ],
        };
    },
    watch: {
        collapse (value) {
            if (value) {
                localStorage.setItem('sidebar-collapse', true);
            }
            this.$emit('operate', 'sidebar-collapse', value);
        },
    },
    methods: {
        changeSkin (color) {
            let skin = `skin-${color.replace(' ', '-')}`;
            this.skinkList.forEach(item => {
                $('body').removeClass(`skin-${item.name.replace(' ', '-')}`);
            });
            $('body').addClass(skin);
            localStorage.setItem('skin', skin);
        },
    },
};
</script>
<style scoped>
    aside {
        position: fixed;
        max-height: 100%;
        overflow: auto;
        padding-bottom: 50px;
        height: 100%;
    }
    .list-unstyled li{
        cursor: pointer;
        float:left; width: 33.33333%; padding: 5px;
    }
    .list-unstyled li a {
        display: block; box-shadow: 0 0 3px rgba(0,0,0,0.4);
    }
    .mini-t-l {
        display:block; width: 20%;float: left;height: 7px; background: #367fa9;
    }
    .mini-t-r {
        display:block; width: 80%;float: left;height: 7px;
    }
    .mini-c-l {
        display:block; width: 20%;float: left;height: 20px; background: #222d32;
    }
    .mini-c-r {
        display:block; width: 80%;float: left;height: 20px; background: #f4f5f7;
    }
</style>
