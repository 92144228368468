<template>
    <div class="history-box">
        <div class="tag-container">

            <el-tag
                class="tag"
                v-for="tag in historyList"
                :key="tag.url"
                :closable="tag.url !== '#homePage'"
                :effect="compareHash(tag.url, currentRoute.url) ? 'dark' : 'plain'"
                @click="handleClick(tag)"
                @close="handleClose(tag)"
                @contextmenu.prevent.native="(e) => handleClickRight(e,tag)">
                {{tag.description}}
            </el-tag>
        </div>
        <div class="handle-list" ref="handleList" v-show="handleVisible">
            <div
                class="list"
                v-for="item in handleList"
                :key="item.value">
                <span @click="handleClickHandle(item)">{{ item.label }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { HANDLE_LIST, HOME_PAGE } from './constants';
export default {
    data () {
        return {
            historyList: [{ ...HOME_PAGE }], // 历史记录list
            currentRoute: {}, // 当前的路由
            handleList: [],
            handleVisible: false,
            rightClickTag: {}, // 右击选中的tag
        };
    },
    watch: {
        handleVisible: function (e) {
            e ? document.body.addEventListener('click', this.closeMenu) : document.body.removeEventListener('click', this.closeMenu);
        },
        rightClickTag: {
            handler (val) {
                const isSame = this.compareHash(val.url, HOME_PAGE.url);
                // 右击的是否是个人主页
                if (!isSame) {
                    this.handleList = [...HANDLE_LIST];
                } else {
                    // 右击的是个人主页，不显示‘关闭当前’选项
                    this.handleList = HANDLE_LIST.filter(item => item.value !== 'closeCurrent');
                }
            },
            immediate: true,

        },
    },
    created () {
        try {
            const menuList = JSON.parse(window.localStorage.getItem('menuList'));
            this.currentRoute = this.getCurrentMenu(menuList) || {};
            const isHomePage = this.compareHash(this.currentRoute.url, HOME_PAGE.url);
            // 如果有路由值并且不是homepage就push历史记录里
            if (this.currentRoute.url && !isHomePage) {
                this.historyList.push(this.currentRoute);
            }
            window.addEventListener('hashchange', () => {
                this.currentRoute = this.getCurrentMenu(menuList) || {};
                const isHave = this.historyList.some(item => this.compareHash(item.url, this.currentRoute.url));
                if (!isHave && this.currentRoute.url) {
                    this.historyList.push(this.currentRoute);
                }
            });
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        compareHash (f = '', s = '') {
            if (['number', 'string'].includes(typeof f) && ['number', 'string'].includes(typeof s)) {
                if (String(f) === String(s)) {
                    return true;
                } else {
                    return String(f).split('?')[0] === String(s).split('?')[0];
                }
            } else {
                return false;
            }
        },
        handleClick (route) {
            window.location.href = route.url;
        },
        handleClose (route) {
            if (this.historyList.length > 1) {
                this.historyList = this.historyList.filter(item => item.url !== route.url);
                if (route.url === window.location.hash) {
                    window.location.href = this.historyList[this.historyList.length - 1].url;
                }
            }
        },
        handleClickRight (e, tag) {
            this.handleVisible = true;
            this.rightClickTag = tag;
            this.$refs['handleList'].style.left = e.x + 'px';
            this.$refs['handleList'].style.top = e.y + 'px';
        },
        getCurrentMenu (arr) {
            let matchRoute = null;
            if (window.location.hash === '') {
                return {
                    url: '#homePage',
                    description: '个人主页',
                };
            }

            for (let item of arr) {
                if ('#' + item.uri === window.location.hash.split('?')[0]) {
                    matchRoute = {
                        url: window.location.hash,
                        description: item.description,
                    };
                    return matchRoute;
                } else if (item.children) {
                    matchRoute = this.getCurrentMenu(item.children);
                    if (matchRoute) {
                        return matchRoute;
                    }
                }
            }
            return matchRoute;
        },
        handleClickHandle (item) {
            if (item.value === 'refresh') {
                window.postMessage({
                    from: 'menuHistory',
                    type: item.value,
                    url: this.rightClickTag.url,
                },);
            } else if (item.value === 'closeCurrent') {
                // 关闭当前右击的记录，如果当前路由是右击的记录，路由跳转至最后一个记录的路由
                if (this.historyList.length > 1) {
                    this.historyList = this.historyList.filter(item => !this.compareHash(item.url, this.rightClickTag.url));
                    if (this.compareHash(this.currentRoute.url, this.rightClickTag.url)) {
                        window.location.href = this.historyList[this.historyList.length - 1].url;
                    }
                }
            } else if (item.value === 'closeOther') {
                // 关闭其他，只留当前右击的记录和主页，并且路由跳转至当前右击的路由
                this.historyList = this.historyList.filter(item => {
                    return this.compareHash(item.url, this.rightClickTag.url) || this.compareHash(item.url, HOME_PAGE.url);
                });
                window.location.href = this.rightClickTag.url;
            } else if (item.value === 'closeAll') {
                // 关闭所有，只留主页
                this.historyList = [{ ...HOME_PAGE }];
                window.location.href = HOME_PAGE.url;
            }
        },
        closeMenu () {
            this.handleVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.history-box {
    width: 100%;
    padding: 10px 15px;
    .tag-container {
        overflow-x: scroll;
        width: 100%;
        background-color: #fff;
        padding: 5px 10px 0 10px;
        white-space: nowrap;
        /* 隐藏标准的滚动条 */
        &::-webkit-scrollbar {
            height: 2px;
        }
        /* 隐藏 IE 和 Edge 浏览器的滚动条 */
        &::-ms-scrollbar {
            height: 2px;
        }
    }
    .tag {
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 5px;
    }
    .handle-list {
        padding: 10px 0;
        background: #fff;
        text-align: center;
        position: fixed;
        z-index: 10000;
        box-shadow: 2px 2px 3px 0 rgba(0,0,0,.3);
        border-radius: 5px;
        span {
            padding: 0 16px;
            display: block;
            line-height: 24px;
            font-size: 12px;
            cursor: pointer;
            &:hover {

                background: #eee;
            }
        }
    }
}
</style>
