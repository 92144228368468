<!-- 表格组件 -->
<template>
  <div>
    <el-table
      :data="data"
      v-loading="loading"
      :border="border"
      style="width: 100%"
      :max-height="tableHeight"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <el-table-column v-if="showIndexColumn" type="index" width="80" align="center"/>
      <el-table-column v-if="showSelectColumn"
        type="selection"
        width="55">
      </el-table-column>
      <!-- 默认对齐方式--居中 -->
      <el-table-column
        v-for="{
          prop,
          type,
          label = '',
          width = '',
          align = 'center',
          slot,
          fixed,
          format= 'YYYY-MM-DD HH:mm:ss',
          ...otherAttrs} in columns"
        :key="prop"
        :prop="prop"
        :label="label"
        :width="width"
        :align="align"
        :fixed="fixed"
        :type="type"
        v-bind="otherAttrs"
      >
        <template slot-scope="scope">
          <slot v-if="slot" :name="slot" :row="scope.row" />
          <span v-if="type === 'image'&&!scope.row[prop]">暂无图片</span>
          <el-image
            v-if="type === 'image'&&scope.row[prop]"
            ref="preview"
            style="width: 40px; height: 40px"
            :src="
              Array.isArray(scope.row[prop])
                ? scope.row[prop][0]
                : scope.row[prop]
            "
            :preview-src-list="
              Array.isArray(scope.row[prop])
                ? scope.row[prop]
                : Array.of(scope.row[prop])
            "
          ></el-image>
          <span v-if="type === 'time'">{{
            scope.row[[prop]]
              ? moment(scope.row[[prop]] * 1000).format(
                format
                )
              : '-'
          }}</span>
          <span v-if="!slot && !type">{{
            scope.row[prop]
          }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from 'moment';
export default {
    name: 'Table',
    props: {
        // 边框
        border: {
            type: Boolean,
            default: () => false,
        },
        // 表格loading
        loading: {
            type: Boolean,
            default: () => false,
        },
        // 表头字段
        columns: {
            type: Array,
            default: () => [],
        },
        // 数据
        data: {
            type: Array,
            default: () => [],
        },
        // 表格高度
        height: {
            type: [Number, String],
            default: 0,
        },
        showIndexColumn: {
            type: Boolean,
            default: () => false,
        },
        showSelectColumn: {
            type: Boolean,
            default: () => false,
        },
    },
    data () {
        return {
            moment,
        };
    },
    computed: {
        tableHeight: function () {
            console.log(parseFloat(this.height || 0) < 360 ? 'none' : this.height);
            return parseFloat(this.height || 0) < 360 ? 'none' : this.height;
        },
    },
    methods: {},
};
</script>
