<template>
    <div class="text-center error-body" style="margin-top: 18%;"> <img src="//cdn.eduzhixin.com/cdn/web/static/img/404.c085271.png"> <h3><a href="/" style="font-size:18px;color:#979dac;margin-top:20px">当前页面似乎受到三体射线的干扰，点此返回首页</a></h3> </div>
</template>

<script>
export default {
    name: 'not-found',
};
</script>

<style>

</style>
