import '@babel/polyfill';
import Vue from 'vue';

import './api/ajax-prefilter';
import TopMenu from './components/Topmenu';

const tvm = new Vue(TopMenu).$mount('#topmenu-root');
export {
    tvm,
};
