import { request } from './request';
// mock地址前缀
const prefix = '';
// const prefix = 'http://yapi.upho2015.com/mock/17/v1';
/**
 * 上传图片接口
 * @param {Object} params
 * @param {String} params.dir 上传图片的目录
 * @param {String} params.file 上传的文件
 * */
export const uploadImage = (params) => request(prefix + '/v1/admin/Classcenter/uploadAd', 'POST', Object.assign({ processData: false, contentType: false }, params));

/**
 * 上传图片的参数dir常量导出
 * 安卓手机：live/android/phone；
 * 苹果手机：live/apple/phone；
 * 安卓平板：live/android/pad；
 * 苹果平板：live/apple/ipad。
 * 选课页：live/class-ads；
 * 推荐课：live/class-recommend;
 * 应用icon: appstore/icon;
 * 截图：appstore/screeshots;
 * 对话页头像：conversation/headimg;
 * 对话页消息图片: conversation/message-img;
 * 资料上传预览图：download/preview;
 * 布置作业：school-assignment/assign;
 * 批阅作业:  school-assignment/review;
 * 题目阅卷后：question-img/checked;
 * 直播课封面底图：live/tpl/cover
 * 直播课详情图底图：live/tpl/content
 * 直播课详情图教师图片 Teacher/CourseDetailsImage/{teacherId}
 */
export const uploadImageDir = {
    liveAndroidPhone: 'live/android/phone',
    liveApplePhone: 'live/apple/phone',
    liveAndroidPad: 'live/android/pad',
    liveApplePad: 'live/apple/ipad',
    liveClassAds: 'live/class-ads',
    liveClassRecommend: 'live/class-recommend',
    appstoreIcon: 'appstore/icon',
    appstoreScreeshots: 'appstore/screeshots',
    conversationHeadimg: 'conversation/headimg',
    conversationMessageImg: 'conversation/message-img',
    downloadPreview: 'download/preview',
    schoolAssignmentAssign: 'school-assignment/assign',
    schoolAssignmentReview: 'school-assignment/review',
    liveTplCover: 'live/tpl/cover',
    liveTplContent: 'live/tpl/content',
    teacherCourseDetailsImage: 'Teacher/CourseDetailsImage/{teacherId}',
};
