export const HANDLE_LIST = [{
    value: 'refresh',
    label: '刷新页面',
}, {
    value: 'closeCurrent',
    label: '关闭当前',
}, {
    value: 'closeOther',
    label: '关闭其他',
}, {
    value: 'closeAll',
    label: '关闭所有',
}];

export const HOME_PAGE = {
    url: '#homePage',
    description: '个人主页',
};
