<template>
    <el-checkbox
        :value="value"
        @change="(val) => $emit('input', val)"
        v-bind="$attrs"
        v-on="$listeners">
        {{label}}
    </el-checkbox>
</template>

<script>
export default {
    props: {
        value: {
            default: '',
        },
        label: {
            default: '',
        },
    },
    computed: {},
    data () {
        return {};
    },
    mounted () { },
};
</script>

<style>

</style>
