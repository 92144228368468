var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',_vm._g(_vm._b({directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.data,"border":_vm.border,"max-height":_vm.tableHeight}},'el-table',_vm.$attrs,false),_vm.$listeners),[(_vm.showIndexColumn)?_c('el-table-column',{attrs:{"type":"index","width":"80","align":"center"}}):_vm._e(),_vm._v(" "),(_vm.showSelectColumn)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_vm._v(" "),_vm._l((_vm.columns),function({
        prop,
        type,
        label = '',
        width = '',
        align = 'center',
        slot,
        fixed,
        format= 'YYYY-MM-DD HH:mm:ss',
        ...otherAttrs}){return _c('el-table-column',_vm._b({key:prop,attrs:{"prop":prop,"label":label,"width":width,"align":align,"fixed":fixed,"type":type},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(slot)?_vm._t(slot,null,{"row":scope.row}):_vm._e(),_vm._v(" "),(type === 'image'&&!scope.row[prop])?_c('span',[_vm._v("暂无图片")]):_vm._e(),_vm._v(" "),(type === 'image'&&scope.row[prop])?_c('el-image',{ref:"preview",refInFor:true,staticStyle:{"width":"40px","height":"40px"},attrs:{"src":Array.isArray(scope.row[prop])
              ? scope.row[prop][0]
              : scope.row[prop],"preview-src-list":Array.isArray(scope.row[prop])
              ? scope.row[prop]
              : Array.of(scope.row[prop])}}):_vm._e(),_vm._v(" "),(type === 'time')?_c('span',[_vm._v(_vm._s(scope.row[[prop]]
            ? _vm.moment(scope.row[[prop]] * 1000).format(
              format
              )
            : '-'))]):_vm._e(),_vm._v(" "),(!slot && !type)?_c('span',[_vm._v(_vm._s(scope.row[prop]))]):_vm._e()]}}],null,true)},'el-table-column',otherAttrs,false))})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }