<template>
    <div>
        <quillEditor
            ref="quill"
            :value="value"
            :options="editorOption"
            @input="($event) => $emit('input', $event)">
        </quillEditor>
        <UploadImage
            :show="showUploadImgDialog"
            :dir="dir"
            @cancel="cancelUploadImg"
            @confirm="getImg"></UploadImage>
    </div>

</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import UploadImage from './components/UploadImage';
export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        dir: {
            type: String,
            default: '',
        },
    },
    components: {
        quillEditor,
        UploadImage,
    },
    data () {
        return {
            showUploadImgDialog: false,
            editorOption: {
                placeholder: '请输入内容',
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline', 'strike'],
                            ['blockquote', 'code-block'],
                            [{ 'header': 1 }, { 'header': 2 }],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'script': 'sub' }, { 'script': 'super' }],
                            [{ 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'direction': 'rtl' }],
                            [{ 'size': ['small', false, 'large', 'huge'] }],
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'font': [] }],
                            [{ 'align': [] }],
                            ['clean'],
                            ['link', 'image', 'video'],
                            ['sourceEditor'],
                        ],
                        handlers: {
                            image: this.imgHandler,
                        },
                    },
                    clipboard: {
                        matchers: [
                            [Node.ELEMENT_NODE, this.desMatcher],
                        ],
                    },
                },
            },
            addRange: null,
        };
    },
    mounted () {

    },
    methods: {
        imgHandler (val) {
            if (val) {
                this.addRange = this.$refs.quill.quill.getSelection();
                this.showUploadImgDialog = true;
            } else {
                this.$refs.quill.quill.format('image', false);
            }
        },
        cancelUploadImg () {
            this.showUploadImgDialog = false;
        },
        getImg (imgUrl) {
            this.showUploadImgDialog = false;
            this.$refs.quill.quill.insertEmbed(this.addRange?.index, 'image', imgUrl);
        },
        // 判断链接是否是本地图片
        isLocalImg (url) {
            return url.indexOf('http') === -1;
        },
        // 判断链接是否是eduzhixin.com 域名下的图片
        isStaticImg (url) {
            return url.indexOf('eduzhixin.com') !== -1;
        },

        // 粘贴拦截
        desMatcher (node, Delta) {
            let ops = [];
            Delta.ops.forEach((item) => {
                if (item.insert && item.insert.image) {
                    let url = item.insert.image;
                    // 如果不是本地图片并且是eduzhixin.com 域名下的图片 正常粘贴
                    if (!this.isLocalImg(url) && this.isStaticImg(url)) {
                        ops.push(item);
                    }
                } else {
                    ops.push(item);
                }
            });
            Delta.ops = ops;
            return Delta;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
