<template>
    <div>
        <header class="main-header">
            <svg style="width: 100%;height: 4px; position:absolute;z-index:1111;" viewBox="0 0 327680 1024"
                 preserveAspectRatio="none">
                <title>banner</title>
                <path fill="#8790e8" class="path1"
                      d="M0 0v2048h12544c-384-640-716.8-1331.2-1049.6-2048h-11494.4z"></path>
                <path fill="#3498b0" class="path2"
                      d="M132172.8 0h-45414.4c-460.8 691.2-947.2 1382.4-1510.4 2048h49203.2c-819.2-665.6-1587.2-1356.8-2278.4-2048z"></path>
                <path fill="#8790e8" class="path3"
                      d="M327680 0h-44339.2c-691.2 691.2-1459.2 1382.4-2278.4 2048h46617.6v-2048z"></path>
                <path fill="#5bc4bf" class="path4"
                      d="M283852.8 0h-152704c691.2 691.2 1459.2 1382.4 2278.4 2048h148121.6c844.8-665.6 1612.8-1356.8 2304-2048z"></path>
                <path fill="#fbaf3f" class="path5"
                      d="M10470.4 0c307.2 716.8 665.6 1408 1049.6 2048h74752c563.2-665.6 1049.6-1356.8 1510.4-2048h-77312z"></path>
            </svg>
            <a href="/" class="logo">
                <span class="logo-mini">
                    <svg class="icon icon-logo" style="width:30px;height:30px;" viewBox="0 0 1024 1024">
                        <title>logo</title>
                        <path fill="#3799b1" class="path1"
                              d="M843.162 154.214c0 0-480.461 0-631.194 0s-160.768-103.014-160.768-103.014h791.962v103.014z"></path>
                        <path opacity="0.3" fill="#000" class="path2" d="M629.146 51.2h125.542v103.014h-125.542v-103.014z"></path>
                        <path fill="#3799b1" class="path3"
                              d="M972.8 972.8h-834.355v-103.014c0 0 523.059 0 673.587 0s160.768 103.014 160.768 103.014z"></path>
                        <path opacity="0.5" fill="#000" class="path4"
                              d="M271.155 869.786h125.542v103.014h-125.542v-103.014z"></path>
                        <path fill="#fbb040" class="path5"
                              d="M337.101 465.715l-219.955-146.842c0 0 82.944-79.667 148.070-33.997s139.674 97.894 139.674 97.894l-67.789 82.944z"></path>
                        <path fill="#fbb040" class="path6"
                              d="M889.446 719.053l-203.162-169.779-62.464 78.848c0 0 55.091 46.899 120.218 103.424 65.126 56.32 145.408-12.493 145.408-12.493z"></path>
                        <path fill="#5bc4bf" class="path7"
                              d="M315.392 870.81v0c0.614-0.614 0.819-1.024 0.819-1.024l656.589-818.586h-287.334c87.45 17.818 30.72 92.57 23.142 101.99v0.205c-0.41 0.614-0.819 0.819-0.819 0.819l-656.589 818.586h287.334c-87.45-17.818-30.925-92.57-23.142-101.99z"></path>
                     </svg>
                </span>
                <span class="logo-lg"><b>质心</b>后台</span>
            </a>
            <nav class="navbar navbar-static-top">
                <span class="sidebar-toggle" role="button" @click="togglSidebar">
                    <span class="sr-only">Toggle navigation</span>
                </span>
                <a href="https://help.eduzhixin.com/index.html" target="_blank"
                   style="position: relative;top: 15px;margin-left: 5px;color: #fff;text-decoration: none;">
                    <i style="vertical-align: sub;">
                        <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="后台-faq-icon" transform="translate(-274.000000, -18.000000)">
                                    <g id="Group-3" transform="translate(274.000000, 18.000000)">
                                        <g id="faq">
                                            <g id="Group-2" transform="translate(5.000000, 3.653846)" fill="#FFFFFF">
                                                <g id="Group">
                                                    <path
                                                        d="M0.96457319,2.24614872 C0.96457319,1.39018765 1.63402692,0.848314233 2.51778904,0.842355307 C3.40480716,0.836374425 4.07566276,1.37239712 4.08540751,2.25277145 C4.08885043,2.56381669 3.83034403,2.9018986 3.24495249,3.41815881 C3.18374483,3.4721382 2.95576114,3.67009492 2.90552771,3.71432519 C2.7743916,3.82978988 2.67748943,3.91931798 2.59290394,4.00501839 C2.35250091,4.24859015 2.2220706,4.4507543 2.21312447,4.67166627 C2.19697004,5.07057703 2.19697004,5.42237168 2.21328432,5.72750472 C2.22173519,5.88556494 2.36814383,6.00740811 2.54029717,5.99964906 C2.7124505,5.99189002 2.84515758,5.85746694 2.83670671,5.69940673 C2.82134007,5.41199783 2.82134007,5.07636382 2.83686656,4.69295919 C2.84139594,4.58111259 2.93610399,4.4851562 3.6773385,3.83145717 C4.38683413,3.20574884 4.71540475,2.77603497 4.70954836,2.24694774 C4.69612474,1.034211 3.72854311,0.261100204 2.51320531,0.26929484 C1.30361473,0.277450724 0.340400109,1.05710192 0.340400109,2.24614872 C0.340400109,2.40439927 0.480126012,2.53268668 0.652486649,2.53268668 C0.824847286,2.53268668 0.96457319,2.40439927 0.96457319,2.24614872 Z"
                                                        id="Path-7" stroke="#FFFFFF" stroke-width="0.567"
                                                        fill-rule="nonzero"></path>
                                                    <circle id="Oval-2" cx="2.5" cy="7.5230773" r="1"></circle>
                                                </g>
                                            </g>
                                            <path
                                                d="M7.5,14 C11.0898509,14 14,11.0898509 14,7.5 C14,6.21146757 13.6244615,4.97083417 12.9178597,3.90745507 C12.4159242,3.15208231 11.7604381,2.50798806 10.9965239,2.0195471 C9.95399101,1.35295947 8.74935264,1 7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,11.0898509 3.91014913,14 7.5,14 Z"
                                                id="Oval" stroke="#FFFFFF" stroke-width="2" fill-rule="nonzero"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </i>
                    <span style="display: inline-block;margin-left: 3px;">质心帮助文档</span>
                </a>
                <div class="navbar-custom-menu">
                    <ul class="nav navbar-nav">
                        <li class="dropdown user user-menu">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" v-if="user">
                                <img :src="user.avatar" class="user-image" alt="User Image"/>
                                <span class="hidden-xs" id="global-user-name" v-if="user"> {{ user.real_name }} </span>
                            </a>
                            <ul class="dropdown-menu ">
                                <li class="user-header" v-if="user">
                                    <img :src="user.avatar" class="img-circle"
                                         alt="User Image">
                                    <p>
                                        <label>{{ user.real_name }} - {{ role }}</label>
                                        <small>Member since {{ user.register_at }}</small>
                                    </p>
                                </li>
                                <li class="user-body" v-if="user">
                                    <div class="row">
                                        <div class="col-xs-4 text-center">
                                        </div>
                                        <div class="col-xs-4 text-center">
                                        </div>
                                        <div class="col-xs-4 text-center">
                                            <label class="btn-logout text-primary" @click="logout">logout</label>
                                        </div>
                                    </div>
                                </li>
                                <li class="user-footer hidden">
                                    <div class="pull-left">
                                        <a href="#" class="btn btn-default btn-flat">Profile</a>
                                    </div>
                                    <div class="pull-right">
                                        <a href="#" class="btn btn-default btn-flat">Sign out</a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a @click="showControlBar = !showControlBar"><i class="glyphicon glyphicon-cog"></i></a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <v-sidebar :userMenusTree="userMenusTree"
                   :user="userInfo"
                   v-if="userInfo"
                   ref="sidebar"
        />
        <control-sidebar :class="controlBarVisible?'control-sidebar-open':''"
                         v-on:operate="operateUpdate"
        />
    </div>
</template>

<script>
import cookie from '@/util/cookie';
import ControlSidebar from './ControlSidebar';
import Sidebar from './Sidebar';
import { logoutAction } from '@/api';
import { DOMAIN } from '@/constants';
import * as Util from '@/util/util';
import MenuHistory from './menu-history';
import Vue from 'vue';

export default {
    name: 'top-menu',
    components: {
        'control-sidebar': ControlSidebar,
        'v-sidebar': Sidebar,
    },
    data () {
        const showSidebar = window.localStorage.getItem('show-sidebar') === 'true';
        return {
            code: null,
            msg: '成功',
            version: '1.0',
            user: null,
            ViewAcl: null,
            userMenusTree: [],
            showControlBar: showSidebar,
            isMobile: new Util().device().isMobile,
        };
    },
    computed: {
        role () {
            let role = [];
            if (this.ViewAcl.all) {
                role.push('超级管理员');
            }
            if (this.ViewAcl.OfflineMarket) {
                role.push('线下市场');
            }
            if (this.ViewAcl.OlineMarket) {
                role.push('线上市场');
            }
            if (this.ViewAcl.Assistant) {
                role.push('助教');
            }
            if (this.ViewAcl.Teacher) {
                role.push('教师');
            }
            if (this.ViewAcl.Finance) {
                role.push('财务');
            }
            if (this.ViewAcl.apGroup) {
                role.push('AP');
            }
            if (this.ViewAcl.call) {
                role.push('呼叫');
            }
            return role.join(',');
        },
        userInfo () {
            return this.user;
        },
        controlBarVisible () {
            return this.showControlBar;
        },
    },
    methods: {
        togglSidebar () {
            let $body = $('body');
            let sidebarClass = this.isMobile ? 'sidebar-open' : 'sidebar-collapse';
            if ($body.hasClass(sidebarClass)) {
                $body.removeClass(sidebarClass);
            } else {
                $body.addClass(sidebarClass);
            }
        },
        getAclMenus () {
            const hash = location.hash.split('?')[0].slice(1);
            $.ajax({
                url: '/v1/admin/Permissions/getAclMenus',
                type: 'GET',
                dataType: 'JSON',
            }).then((data) => {
                if (data.result === 1 && location.hash === '#login') {
                    window.location = '/';
                }
                this.user = data.user;
                this.ViewAcl = data.ViewAcl;
                this.userMenusTree = this.setExpendedTab(data.userMenusTree, hash);
                window.localStorage.setItem('menuList', JSON.stringify(this.userMenusTree));
                new Vue(MenuHistory).$mount('#menu-history');
            }).fail(() => {
                if (window.location.href.includes('return_url')) {
                    return false;
                }
                if (window.location.hash.includes('#login') || !window.location.hash) {
                    window.location.href = window.location.origin + `/#login`;
                    return false;
                }
                window.location.href = window.location.origin + `/#login?return_url=${encodeURIComponent(window.location.href)}`;
            });
        },
        setExpendedTab (tree = [], hash) {
            const len = tree.length;
            for (let i = 0; i < len; i++) {
                let item = tree[i];
                if (item.uri === hash) {
                    return tree;
                }
                if (item.children) {
                    const includeTree = item.children.filter(routes => {
                        return routes.uri === hash;
                    });
                    if (includeTree.length) {
                        item.expended = true;
                        return tree;
                    }
                }
            }
            return tree;
        },
        logout () {
            logoutAction({
                xhrFields: {
                    withCredentials: true,
                },
            }).then((data) => {
                if (data.code === 1) {
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    cookie.delCookie('Authorization', DOMAIN);
                    window.location.reload();
                }
            });
        },
        operateUpdate (name, value) {
            if (name === 'sidebar-collapse') {
                this.showControlBar = false;
                if (value) {
                    this.$refs.sidebar.autoCollapse();
                } else {
                    this.$refs.sidebar.closeAutoCollapse();
                }
            }
        },
    },
    mounted () {
        this.getAclMenus();
    },
};
</script>

<style scoped>
    .user-menu a {
        height: 50px;
    }
    .btn-logout {
        cursor: pointer;
    }
</style>
