define([], function () {
    return {
        SUBJECT_TYPE_PHY: 'PHY',
        SUBJECT_TYPE_BIO: 'BIO',
        SUBJECT_TYPE_CHEM: 'CHEM',
        SUBJECT_TYPE_MATH: 'MATH',
        SUBJECT_TYPE_IT: 'IT',
        SUBJECT_TYPE_ZI: 'ZI',
        SUBJECT: {
            PHY: '物理',
            BIO: '生物',
            CHEM: '化学',
            MATH: '数学',
            CHN: '语文',
            ENG: '英语',
            INFO: '信息科学',
            ZI: '自招',
        },
        SUBJECT_TYPE: {
            PHY: '物理',
            BIO: '生物',
            CHEM: '化学',
            MATH: '数学',
            CHN: '语文',
            ENG: '英语',
        },
        GRADE_TYPES: [
            '幼儿园',
            '一年级',
            '二年级',
            '三年级',
            '四年级',
            '五年级',
            '六年级',
            '初一',
            '初二',
            '初三',
            '高一',
            '高二',
            '高三',
        ],
        ONLINE_NO: 0,
        ONLINE_YES: 1,
        ONLINE: { 0: '下线', 1: '上线' },
        TEACHER_TYPE: ['教师', 'TA', 'SP'],
        CLASS_TYPE: {
            1: '质心营',
            2: '直播课',
            3: '面授课',
        },
        WEBSITE_TYPE: {
            1: '质心官网',
            2: '质心灵动量',
        },
        CAMP_TYPES_SEMI1: 1,
        CAMP_TYPES_SEMI2: 14,
        CAMP_TYPES_SEMI3: 15,
        CAMP_TYPES_FINALS: 2,
        CAMP_TYPES_EXP_SEMI: 3,
        CAMP_TYPES_EXP_FINALS: 4,
        CAMP_TYPES_ZI: 5,
        CAMP_TYPES_BIO1: 6,
        CAMP_TYPES_BIO2: 11,
        CAMP_TYPES_BIO3: 12,
        CAMP_TYPES_BIO_G: 7,
        CAMP_TYPES_BASIC_1: 8,
        CAMP_TYPES_BASIC_2: 13,
        CAMP_TYPES_CHEM_B: 9,
        CAMP_TYPES_CHEM_F: 10,
        CAMP_TYPES: {
            1: '冲复赛班（一）',
            14: '冲复赛班（二）',
            15: '冲复赛班（三）',
            2: '冲决赛班（一）',
            16: '冲决赛班（二）',
            17: '冲决赛班（三）',
            3: '复赛实验',
            4: '决赛实验',
            5: '物理自招',
            6: '生物冲联赛班（一）',
            11: '生物冲联赛班（二）',
            12: '生物冲联赛班（三）',
            18: '生物冲联赛班（四）',
            19: '生物冲联赛班（五）',
            7: '生物冲国赛班',
            8: '竞赛基础班（一）',
            13: '竞赛基础班（二）',
            9: '化学国初冲刺班',
            10: '化学国决冲刺班',
        },
        LIVE_TYPES: {
            '免费课': '讲座',
            '专题课': '课程（专题课）',
            '系统课': '课程（系统课）',
            '学院直播': '学院直播',
            '引流课': '引流课',
            '线上营': '线上营',
        },
        OFFLINE_TYPES: {
            1: '课程',
            2: '考试',
            3: '同步班',
            4: '强基班',
            5: '竞赛班',
        },
        CLASS_TYPES: {
            1: '质心营',
            2: '直播课',
            3: '面授课',
        },
        CLASS_SUBTYPES: {
            1: 'CAMP_TYPES',
            2: 'LIVE_TYPES',
            3: 'OFFLINE_TYPES',
        },
        CLASS_TYPES_CAMP: 1,
        CLASS_TYPES_LIVE: 2,
        CLASS_TYPES_OFFLINE: 3,
        ON_JOB: { 0: '离职', 1: '在职' },
        CONTRACT_TYPE: { 0: '全职', 1: '兼职' },
        SEASON: {
            1: '春季',
            2: '夏季',
            3: '秋季',
            4: '冬季',
        },
        CYCLE_WEEKS: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日', '每天', '无周期'],
        WEEKS: { 1: '周一', 2: '周二', 3: '周三', 4: '周四', 5: '周五', 6: '周六', 7: '周日' },
        PERIODS: {
            0: '零期',
            1: '一期',
            2: '二期',
            3: '三期',
        },
        CYCLE_WEEKS_DATE: [1, 2, 3, 4, 5, 6, 0, 10, false],
        ROUNDS: {
            0: '无轮次',
            1: '第一轮',
            2: '第二轮',
            3: '第三轮',
        },
        ADJUST_STATUS: {
            0: '无',
            1: '调课申请中',
            2: '审核通过待通知',
            3: '已完成',
            4: '已取消',
            5: '已驳回',
        },
        VERIFY_NO: 0,
        VERIFY_YES: 1,
        CLASS_VERIFY: { 0: '不验证', 1: '验证' },
        TEST_NO: 0,
        TEST_YES: 1,
        CLASS_TEST: { 0: '不是', 1: '是' },
        GRADE_TYPE: {
            'high': '高中',
            'middle': '初中',
            'primary': '小学',
        },
        GRADE: {
            '': '高中',
            'J_': '初中',
            'P_': '小学',
        },
        // SEARCH_SUBJECT_TYPE: {
        //     PHY: '物理',
        //     BIO: '生物',
        //     CHEM: '化学',
        //     MATH: '数学',
        //     CHN: '语文',
        //     ENG: '英语',
        //     // INFO: '信息科学',
        // },
        EN_ZH_SUBJECT_TYPE: {},
        DEFAULT_SUBJECT: '物理',
        DEFAULT_GRADE: '高中',
        ICONLIST: [
            'glyphicon-home',
            'glyphicon-flag',
            'glyphicon-facetime-video',
            'glyphicon-briefcase',
            'glyphicon-map-marker',
            'glyphicon-user', 'glyphicon-upload',
            'glyphicon-play-circle',
            'glyphicon-eye-open', 'glyphicon-grain',
            'glyphicon-calendar', 'glyphicon-file',
            'glyphicon-usd', 'glyphicon-question-sign',
            'glyphicon-cog',
            'glyphicon-random',
            'glyphicon-gift',
            'glyphicon-comment',
            'glyphicon-certificate',
            'glyphicon-heart-empty',
            'glyphicon-stats',
            'glyphicon-pencil',
            'glyphicon-cloud-upload',
            'glyphicon-cloud-download',
            'glyphicon-list',
            'glyphicon-list-alt',
            'glyphicon-tasks',
        ],
        CAMP_ATTENTIONS: ['报名', '住宿', '饮食', '特殊'],
        SEARCH_SCORE_CATEGORY: {
            1: '官方考试',
            2: '质心营',
            3: 'U/GPHO 月考',
        },
        STOCK_TYPE: {
            '1': '赠品',
            '2': '资料',
        },
        'roleLevel': {
            'fresh': '1',
            'super': '2',
            'juniuor': '3',
        },
        CONTENT_TYPE: {
            1: '竞赛',
            2: '课内',
        },
        MAJOR_GRADES: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '初一', '初二', '初三', '高一', '高二', '高三', '无年级'],
        CLASS_NO_DIFFICULT: {
            '初中': {
                1: '尖子班（难度:2星）',
                2: '超常班（难度:3星）',
                3: '精英班（难度:4星）',
                4: '创新班（难度:5星）',
            },
            '高中': {
                5: '尖子班（目标211）',
                6: '突破班（目标985）',
                7: '强基班（目标强基）',
            },
        },
        CLASS_NO_LIVE_DIFFICULT: {
            '高中': {
                0: '无',
                8: '目标 985',
                9: '目标强基',
            },
        },
        LIANBAO_TYPE: {
            1: '多学科联报',
            2: '暑秋联报',
            3: '寒春联报',
            4: '秋寒春联报',
            5: '春暑秋联报',
            6: '多学科暑秋联报',
            7: '多学科寒春联报',
            8: '多学科秋寒春联报',
            9: '多学科春暑秋联报',
            99: '联报活动',
        },
        Certification_Subject: {
            1: '质心',
            2: '灵动量',
            3: '星彗',
            4: '墨科圣',
        },
        Application_Type: {
            1: '开放平台账号',
            2: '移动应用',
            3: '网页应用',
            4: '订阅号',
            5: '服务号',
            6: '小程序',
        },
        User_Role: {
            0: '游客',
            1: '管理员',
            2: '老师',
            3: '学生',
            4: '家长',
        },
        REGION_BELONG: {
            '北京': '北京',
            '深圳': '深圳',
        },
        GOODS_TYPE: {
            1: '冬/夏令营',
            2: '直播课',
            4: '夸克',
            5: '面授课',
            9: '食宿费',
            11: '质心币',
            12: '质子',
            13: '资料费',
            15: '年卡会员',
            16: '学习计划',
            18: '在线收款',
        },
        CHANNEL: {
            'zhixin_bj': '北京质心教育科技有限公司',
            'ldl_sz': '深圳灵动量教育培训中心有限公司',
        },
        ACADEMICSECTION_AND_GRADE: {
            '高中': {
                subject: ['PHY', 'BIO', 'CHEM', 'MATH', 'CHN', 'ENG', 'ZI'],
                grade: ['高一', '高二', '高三', '无年级'],
            },
            '初中': {
                subject: ['PHY', 'BIO', 'CHEM', 'MATH', 'CHN', 'ENG'],
                grade: ['初一', '初二', '初三', '无年级'],
            },
            '小学': {
                subject: ['PHY', 'BIO', 'CHEM', 'MATH', 'CHN', 'ENG'],
                grade: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '无年级'],
            },
        },
        KNOWLEDGE_BELONG: {
            质心官网: '质心官网',
            开罗尔: '开罗尔',
        },
        COURSE_BELONG: {
            质心官网: '质心官网',
            开罗尔: '开罗尔',
            质心灵动量: '质心灵动量',
        },
    };
});
