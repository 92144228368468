<template>
    <aside class="main-sidebar">
        <section class="sidebar" style="height: auto;">
            <div class="user-panel">
                <div class="pull-left image">
                    <img v-if="user" :src="user.avatar" class="img-circle" alt="User Image">
                    <a href="#homePage" style="position: absolute;top:0;left:0;bottom:0;right:0;"></a>
                </div>
                <div class="pull-left info">
                    <p><span v-if="user">{{ user.real_name }}</span></p>
                    <a href="#"><i class="fa fa-circle text-success"></i> Online</a>
                </div>
            </div>
            <form method="get" class="sidebar-form" onsubmit="return false">
                <div class="input-group">
                    <input type="text" v-model="keyword" @input="searchInput" name="q" autocomplete="off" class="form-control" placeholder="Search...">
                    <i class="fa fa-close btn-clear" v-show="keyword" @click="clearSearchInput"></i>
                    <span class="input-group-btn" style="line-height: 1;">
                        <button type="submit" name="search" class="btn btn-flat" :data-keyword="keyword" @click="filterMenu(keyword)" :disabled="!keyword">
                            <i class="fa fa-search"></i>
                        </button>
                    </span>
                </div>
            </form>
            <ul class="sidebar-menu">
                <li class="header">MAIN NAVIGATION</li>
                <template v-if="tree">
                    <li class="treeview" v-for="(items, index) in tree" :key="index" :class="items.expended?'active':''">
                        <a style="cursor: pointer" :href="items.children?'':'#'+items.uri" @click="expendTree(index, $event)" :data-index="index" data-level="1">
                            <i class="glyphicon" :class="items.icon"></i>
                            <span class="text">{{items['description']}}</span>
                            <span v-if="items.children" class="pull-right glyphicon glyphicon-chevron-down"></span>
                        </a>
                        <transition name="slide"
                                    :duration="duration"
                                    v-on:after-enter="afterEnter"
                                    v-on:enter="enter"
                                    v-on:leave="leave"
                                    v-on:after-leave="afterLeave"
                        >
                            <ul :class="'treeview-menu' + (items.children && items.expended ? '':' hide')">
                                <li>
                                    <a :class='{"active-menu": activeMenu.uri === item.uri }' @click="handleClickMenu(item)" v-for="(item, index) in items.children" :key="index" :href="'#'+item.uri">
                                        <i class="fa fa-circle-o" v-show="!item.icon"></i>
                                        <i class="glyphicon" :class="item.icon" v-show="item.icon"></i>
                                        <span>{{item['description']}}</span>
                                    </a>
                                </li>
                            </ul>
                        </transition>
                    </li>
                </template>
            </ul>
        </section>
        <!-- <div v-else style="padding: 10px;"> -->
            <!-- <ul class="skeleton-paragraph" v-for="i in 4" :key="i">
                <li style="width: 64%"></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul> -->
        <!-- </div> -->
    </aside>
</template>

<script>
export default {
    name: 'sidebar',
    props: {
        'userMenusTree': Array,
        'user': Object,
    },
    data () {
        const collapseSidebar = window.localStorage.getItem('sidebar-collapse') === 'true';

        return {
            autoFold: true,
            Undefined: void 0,
            userMenusTreeData: this.userMenusTree,
            duration: 300,
            collapseSidebar,
            keyword: '',
            activeMenu: {},
        };
    },
    computed: {
        tree () {
            return this.userMenusTreeData;
        },
    },
    methods: {
        handleClickMenu (menu) {
            this.activeMenu = menu;
        },
        clearSearchInput () {
            this.keyword = '';
            this.filterMenu(this.keyword);
        },
        searchInput () {
            this.filterMenu(this.keyword);
        },
        filterMenu (name) {
            const $subMenu = $('.sidebar-menu li>a');
            const $child = $('.treeview-menu');
            $subMenu.removeClass('hide');
            $child.addClass('hide');
            if (!name) return;
            $child.removeClass('hide');
            $subMenu.each((index, element) => {
                let isParent = Number($(element).attr('data-level')) === 1;
                let parentHtml = $(element).parents('ul.treeview-menu').siblings().find('span').html();
                const includeUri = $(element).attr('href').toUpperCase().indexOf(name.toString().toUpperCase()) > -1;
                if ($(element).find('span').html().indexOf(name) > -1 || includeUri) {
                    if (isParent) {
                        this.expendTree(Number($(element).attr('data-index')));
                    }
                } else if (isParent || (!isParent && parentHtml.indexOf(name) === -1)) {
                    $(element).removeClass('show').addClass('hide');
                }
            });
        },
        enter (el) {
            let $el = $(el);
            el.dataset.height = $el.height();
            $el.css('height', 0);
        },
        afterEnter (el) {
            let $el = $(el);
            $el.css('height', el.dataset.height);
        },
        leave (el) {
            let $el = $(el);
            el.dataset.height = $el.height();
            $el.css('height', 0);
        },
        afterLeave (el) {
            let $el = $(el);
            $el.css('height', '');
        },
        expendTree (index, event) {
            let tree = this.tree;
            let items = tree[index];
            if (items.children && items.children.length) {
                if (event) {
                    event.preventDefault();
                }
            }
            if (this.autoFold) {
                items.expended = !items.expended;
                if (this.lastExpendedIndex === this.Undefined) {
                    const activeItem = tree.findIndex(item => item.expended);
                    this.lastExpendedIndex = activeItem >= 0 ? activeItem : this.Undefined;
                    if (this.lastExpendedIndex && this.lastExpendedIndex !== index) {
                        tree[this.lastExpendedIndex].expended = false;
                    }
                } else if (this.lastExpendedIndex !== index) {
                    tree[this.lastExpendedIndex].expended = false;
                }
                this.lastExpendedIndex = index;
            } else {
                items.expended = !items.expended;
            }
            tree[index] = items;
            this.userMenusTreeData = [...tree];
        },
        autoCollapse () {
            $('body').addClass('sidebar-collapse');
            let $sidebar = $('.main-sidebar');
            $sidebar.on('mouseenter', (e) => {
                $('body').removeClass('sidebar-collapse');
            });
            $sidebar.on('mouseleave', () => {
                $('body').addClass('sidebar-collapse');
            });
        },
        closeAutoCollapse () {
            $('body').removeClass('sidebar-collapse');
            let $sidebar = $('.main-sidebar');
            $sidebar.off('mouseenter');
            $sidebar.off('mouseleave');
        },
    },
    mounted () {
        const hash = location.hash.split('?')[0].slice(1);
        this.activeMenu = {
            uri: hash,
        };

        if (this.collapseSidebar) {
            this.autoCollapse();
        }
        const skin = localStorage.getItem('skin') || 'skin-yellow-light';
        if (skin) {
            $('body').addClass(skin);
        }
        window.addEventListener('hashchange', () => {
            const hash = location.hash.split('?')[0].slice(1);
            this.activeMenu = {
                uri: hash,
            };
            let openIndex = null;
            this.tree.find((item, index) => {
                if (item.children && item.children.some(cItem => cItem.uri === hash)) {
                    openIndex = index;
                    item.expended = false;
                }
            });
            if (openIndex) {
                this.expendTree(openIndex);
            }
        });
    },
};
</script>
<style scoped lang="scss">
@keyframes ant-skeleton-loading {
    0% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}
.skeleton-paragraph{
    margin-top: 24px;
    padding: 0;
    >li {
        width: 100%;
        height: 16px;
        background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
        background-size: 400% 100%;
        animation: ant-skeleton-loading 1.4s ease infinite;
    }
    li + li{
    margin-top: 16px;
    }
}
aside.main-sidebar::-webkit-scrollbar {
    display: none;
}
.main-sidebar {
    height: 100%;
    overflow-y: auto;
}
::-webkit-scrollbar {
    background-color: transparent;
}
.treeview-menu {
    /*display need set block, aim to animate*/
    display: block;
    overflow: hidden;
    transition: height .6s ease;
    .active-menu {
        background: #99e9e5;
        color: #000;
    }
}
.treeview {
    transition: all .4s ease;
    .glyphicon-chevron-down {
        width: 14px;
        transition: all .4s ease;
    }
    &.active {
        .glyphicon-chevron-down {
            transform: rotate(270deg);
            transition: all .4s ease;
        }
    }
}
.btn-clear {
    position: absolute;
    right: 40px;
    top: 50%;
    color: rgba(51, 51, 51, 51%);
    z-index: 10;
    margin-top: -7px;
    cursor: pointer;
}
</style>
<style>
    .skin-purple .sidebar-form input[type="text"] {
        color: #fff;
    }
</style>
