// 字典
import { request } from './request';
const prefix = 'ProxyAdmin';
const VERSION = '/v1';
// const prefix = 'https://yapi.upho2015.com/mock/17';
// 获取用户信息
export const getUserInfo = (params) => request(VERSION + '/User/getInfo', 'get', params, prefix);
// 获取用户角色
export const getUserRole = (params) => request(VERSION + '/admin/UserRole/get', 'POST', params, prefix);
// 获取学科列表
export const getSubjectList = (params) => request(VERSION + '/Subject/getList', 'get', params, prefix);
