<template>
    <el-select
        :value="value"
        @change="(val) => $emit('input', val)"
        v-bind="$attrs"
        v-on="$listeners">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled">
        </el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            default: '',
        },
        options: {
            default: () => [],
            type: Array,
        },
    },
    watch: {
    },
    computed: {},
    data () {
        return {};
    },
    mounted () {},
};
</script>

<style>

</style>
