<template>
    <el-input
        :value="value"
        @input="(val) => $emit('input', val)"
        v-bind="$attrs"
        v-on="$listeners">
    </el-input>
</template>

<script>
export default {
    props: {
        value: {
            default: '',
            type: Number || String,
        },
    },
    computed: {},
    data () {
        return {};
    },
    mounted () { },
};
</script>

<style>

</style>
