import { STATIC_CDN_URL } from '@/constants';

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    // options对象 包括accepts、crossDomain、contentType、url、async、type、headers、error、dataType等许多参数选项
    // originalOptions对象 就是你为$.ajax()方法传递的参数对象，也就是 { url: "/index.php" }
    // jqXHR对象 就是经过jQuery封装的XMLHttpRequest对象(保留了其本身的属性和方法)
    const {
        url,
    } = options;
    if (url.indexOf('/v1/') === 0 || url.indexOf('/v2/') === 0) {
    } else if (url.indexOf('/json/') === 0) {
        // /json资源请求， 加cdn前缀
        options.url = STATIC_CDN_URL + url;
    } else if (url.indexOf('/') === 0 && url.indexOf('/mock/') !== 0) {
        // 本地请求地址加"/v1"前缀
        options.url = '/v1' + url;
    } else if (/^[a|A]dmin/.test(url)) {
        // 本地请求地址加"/v1"前缀
        options.url = '/v1/' + url;
    }
});
