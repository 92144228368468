define([
    'cookie',
], function () {
    function Util () {
        this.$loading();
    }

    Util.prototype = {
        constructor: Util,
        $loading: function () {
            $.fn.extend({
                loading: function (isDisplay) {
                    let $this = this;
                    let $loading = $this.find('> .loading');
                    if (!$loading.length) {
                        $loading = $('<div class="loading"><span>loading...</span></div>');
                        $this.append($loading);
                    }
                    if (isDisplay) {
                        $loading.hide = false;
                        $this.find($loading).fadeIn();
                    } else {
                        $loading.hide = true;
                        $this.find($loading).fadeOut();
                    }
                },
            });
        },
        handleGrade: function (graduationYear) {
            let now = new Date().getFullYear();
            let month = new Date().getMonth() + 1;
            let value = graduationYear - now + (month >= 9 ? 0 : 1);
            let grade = '';
            switch (value) {
            case 1:
                grade = '高三';
                break;
            case 2:
                grade = '高二';
                break;
            case 3:
                grade = '高一';
                break;
            case 4:
                grade = '初三';
                break;
            case 5:
                grade = '初二';
                break;
            case 6:
                grade = '初一';
                break;
            default:
                grade = '其他';
                break;
            }
            return grade;
        },
        convertTime: function (second) {
            if (second === 0) {
                return '无';
            } else {
                var seconds = Number(second) * 1000 + 28800000;
                var date = new Date(seconds);
                return date.toISOString().slice(0, 10);
            }
        },
        convertTimeFormat: function (second, begin, end, format) {
            if (!second) {
                return '无';
            } else {
                var seconds = Number(second) * 1000 + 28800000;
                var date = new Date(seconds);
                return date.toISOString().slice(begin, end).replace(/-/g, format).replace('T', ' ');
            }
        },
        countDown: function (options, thisEl, callback) {
            var seconds, minutes, hours, days, eventDate, currentDate,
                interval;
            thisEl = $(thisEl);
            var $days = thisEl.find('.days');
            var $hours = thisEl.find('.hours');
            var $minutes = thisEl.find('.minutes');
            var $seconds = thisEl.find('.seconds');
            var settings = {
                'date': thisEl.attr('data-end'),
                'format': null,
            };
            if (options) {
                $.extend(settings, options);
            }

            // main
            function countdown_run () {
                eventDate = Math.floor(settings.date / 1000);
                currentDate = Math.floor($.now() / 1000);

                seconds = eventDate - currentDate;
                if (Number(seconds) < 0) {
                    callback(thisEl);
                    clearInterval(interval);
                }

                days = Math.floor(seconds / (60 * 60 * 24));
                seconds -= days * 60 * 60 * 24;

                hours = Math.floor(seconds / (60 * 60));
                seconds -= hours * 60 * 60;

                minutes = Math.floor(seconds / 60);
                seconds -= minutes * 60;

                days = (String(days).length >= 2) ? days : '0' + days;
                hours = (String(hours).length >= 2) ? hours : '0' + hours;
                minutes = (String(minutes).length >= 2) ? minutes : '0' + minutes;
                seconds = (String(seconds).length >= 2) ? seconds : '0' + seconds;

                if (!isNaN(eventDate)) {
                    $days && $days.text(days);
                    $hours && $hours.text(hours);
                    $minutes && $minutes.text(minutes);
                    $seconds && $seconds.text(seconds);
                } else {
                    console.info('Invalid date. Here\'s an example: 2016 10 13 17:30:00');
                    clearInterval(interval);
                }
            }

            countdown_run();

            interval = setInterval(countdown_run, 1000);
        },
        scrollSubNavFix: function ($target, $next, tofixed, tostatic, zIndex) {
            var $targetParent = $target.parent();
            var offset = 0;
            var navHeight = $('#zhixin-nav').height();
            var height = $target.height();
            var needScroll = $targetParent.length > 0;
            var nexSiblingTop = 0;
            zIndex = zIndex + '' || '1';
            $(window).on('scroll', function () {
                if (needScroll) {
                    nexSiblingTop = $next.offset().top - navHeight - height;
                    offset = $targetParent.offset().top - $(this).scrollTop();
                    if (offset < navHeight) {
                        $target.css({
                            'position': 'fixed',
                            'top': navHeight + 'px',
                            'width': '100%',
                            'z-index': zIndex,
                        });
                        if (tofixed) {
                            tofixed();
                        }
                    }
                    if ($(this).scrollTop() < nexSiblingTop) {
                        $target.css({
                            position: 'static',
                            top: navHeight + 'px',
                        });
                        if (tostatic) {
                            tostatic();
                        }
                    }
                }
            });
        },
        zhixinModal: function (data, confirmCallback, cancelCallback) {
            $('#zhixin-alert').find('.modal-title').html(data.title || '提示');
            data.showModal = data.showModal || 'show';
            data.showCancelButton = data.showCancelButton !== false;
            data.showConfirmButton = data.showConfirmButton !== false;
            var $footer = $('#zhixin-alert .modal-footer');
            var $header = $('#zhixin-alert .modal-header');
            var $body = $('#zhixin-alert .modal-body');
            if (data.showModal === 'show') {
                $('#zhixin-alert .modal-dialog').removeClass('modal-sm modal-lg').addClass(data.size);
                if (data.titleAlign) {
                    $header.css({
                        'padding-bottom': '16px',
                    });
                }
                $('#zhixin-alert .modal-title').css({
                    'text-align': data.titleAlign || 'left',
                });
                $footer.css({
                    'text-align': data.footerAlign || 'right',
                });
                $body.css({
                    'text-align': data.titleAlign || 'left',
                });
                if (data.desp) {
                    $body.html(data.desp);
                    $body.removeClass('hidden');
                } else {
                    $body.addClass('hidden');
                }
                if (data.showCancelButton) {
                    $footer.find('.btn-cancel').removeClass('hidden');
                    $footer.find('.btn-cancel').text(data.cancelButtonText || '取消');
                } else {
                    $footer.find('.btn-cancel').addClass('hidden');
                }
                if (data.showConfirmButton) {
                    if (data.confirmButtonClass) {
                        $footer.find('.btn-confirm').removeClass('btn-success').addClass(data.confirmButtonClass);
                    } else {
                        $footer.find('.btn-confirm').removeClass().addClass('btn btn-md btn-success btn-confirm hidden');
                    }
                    $footer.find('.btn-confirm').text(data.confirmButtonText || '确认');
                    $footer.find('.btn-confirm').removeClass('hidden');
                } else {
                    $footer.find('.btn-confirm').addClass('hidden');
                }
                if (data.customButton) {
                    $footer.find('.btn-custom').remove();
                    $footer.prepend(data.customButton);
                } else {
                    $footer.find('.btn-custom').addClass('hidden');
                }
                if (data.icon) {
                    $('#zhixin-alert .zm-icon').html(data.icon);
                }
                if (data.type) {
                    if (!data.icon) {
                        $('#zhixin-alert .zm-icon').not('zm-' + data.type).css({
                            'display': 'none',
                        });
                        $('#zhixin-alert .zm-' + data.type).css({
                            'display': 'block',
                        });
                    } else {
                        $('#zhixin-alert .zm-icon').html(data.icon);
                    }
                } else {
                    $('#zhixin-alert .zm-icon').css({
                        'display': 'none',
                    });
                }
            }
            $footer.find('.btn.single').removeClass('single');
            if ($footer.find('.btn').not('.hidden').length === 1) {
                $footer.find('.btn').not('.hidden').addClass('single');
            }
            $footer.find('.btn-confirm').off().on('click', function () {
                $('#zhixin-alert').trigger('confirm');
            });

            $('#zhixin-alert').off().on('confirm', function () {
                confirmCallback && confirmCallback(true);
            });
            // show || hide
            $('#zhixin-alert').modal(data.showModal);
            // #courses 页面的导航z-index 太大了
            $('#zhixin-alert').css('z-index', '20000000');
            $('#zhixin-alert').on('hidden.bs.modal', function () {
                cancelCallback && cancelCallback();
            });
        },
        setCookie: function (c_name, value, expiredays) {
            $.cookie(c_name, value, expiredays);
        },
        getCookie: function (c_name) {
            if (document.cookie.length > 0) {
                var c_start = document.cookie.indexOf(c_name + '=');
                if (c_start !== -1) {
                    c_start = c_start + c_name.length + 1;
                    var c_end = document.cookie.indexOf(';', c_start);
                    if (c_end === -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return '';
        },
        checkCookie: function (username) {
            username = this.getCookie(username);
            if (username != null && username !== '') {
                return true;
            }
            return false;
        },
        encodes: function (str) {
            str = str.toString();
            if (!str || str.length === 0) return '';
            var s = '';
            s = str.replace(/&amp;/g, '&');
            s = s.replace(/<(?=[^o][^)])/g, '&lt;');
            s = s.replace(/</g, '&lt;');
            s = s.replace(/>/g, '&gt;');
            // s = s.replace(/\"/g, '&#39;');
            // eslint-disable-next-line
            s = s.replace(/\"/g, '&quot;');
            s = s.replace(/\n/g, '<br>');
            return s;
        },
        isProduction: function () {
            return window.location.host.toLowerCase() === 'www.eduzhixin.com';
        },
        getLocation: function (href, options) {
            var a = document.createElement('a');
            a.href = href;
            var getParams = function (search) {
                var searchParams = search.replace('?', '').split('&');
                var params = {};
                for (var i = 0; i < searchParams.length; i++) {
                    params[searchParams[i].split('=')[0]] = searchParams[i].split('=')[1];
                }
                return params;
            };
            a.params = getParams(decodeURIComponent(a.search));
            if (options) {
                for (var key in options) {
                    if (a[key]) {
                        options[key] = a[key];
                    }
                }
                return options;
            }
            return {
                host: a.host,
                href: a.href,
                hostname: a.hostname,
                hash: a.hash,
                pathname: a.pathname,
                search: a.search,
                username: a.username,
                origin: a.origin,
                protocol: a.protocol,
                port: a.port,
                params: a.params,
            };
        },
        utf16toEntities: function (str) {
            var patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
            str = str.replace(patt, function (char) {
                var H, L, code;
                if (char.length === 2) {
                    H = char.charCodeAt(0); // 取出高位
                    L = char.charCodeAt(1); // 取出低位
                    code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
                    return '&#' + code + ';';
                } else {
                    return char;
                }
            });
            return str;
        },
        uncodeUtf16: function (str) {
            // eslint-disable-next-line
            var reg = /\&#.*?;/g;
            var result = str.replace(reg, function (char) {
                var H, L, code;
                if (char.length === 9) {
                    code = parseInt(char.match(/[0-9]+/g));
                    H = Math.floor((code - 0x10000) / 0x400) + 0xD800;
                    L = (code - 0x10000) % 0x400 + 0xDC00;
                    return unescape('%u' + H.toString(16) + '%u' + L.toString(16));
                } else {
                    return char;
                }
            });
            return result;
        },
        timeToString: function (timestamp) {
            var time = new Date(0).setUTCSeconds(timestamp);
            var da = new Date(time);
            var year = da.getFullYear() + '年';
            var month = da.getMonth() + 1 + '月';
            var date = da.getDate() + '日';
            var h = da.getHours() > 9 ? da.getHours() : '0' + da.getHours();
            var mm = da.getMinutes() > 9 ? da.getMinutes() : '0' + da.getMinutes();
            return [year, month, date].join('') + ' ' + h + ':' + mm;
        },
        device: function () {
            let isMobile = false;
            let isWechat = false;
            let isMqq = false;
            let isAndroid = false;
            let isIOS = false;
            let isIPad = false;
            let isQuark = false;
            let isSafari = false;
            let userAgentInfo = navigator.userAgent;
            let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
            for (let v = 0; v < Agents.length; v++) {
                if (userAgentInfo.indexOf(Agents[v]) > 0) {
                    isMobile = true;
                    break;
                }
            }
            if (/ipad/.test(userAgentInfo.toLowerCase())) {
                isIPad = true;
            }
            if (/iphone|ipad|ipod/.test(userAgentInfo.toLowerCase())) {
                isIOS = true;
            }
            if (/android|linux/.test(userAgentInfo.toLowerCase())) {
                isAndroid = true;
            }
            if (userAgentInfo.toLowerCase().indexOf('micromessenger') !== -1) {
                isWechat = true;
            }
            if (userAgentInfo.toLowerCase().indexOf(' qq/') !== -1) {
                isMqq = true;
            }
            if (userAgentInfo.toLowerCase().indexOf('quark') !== -1) {
                isQuark = true;
            }
            if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
                isSafari = true;
            }
            return {
                isMobile,
                isWechat,
                isMqq,
                isAndroid,
                isIOS,
                isIPad,
                isQuark,
                isSafari,
            };
        },
        weChatShare: function (wx, params) {
            wx.config({
                debug: false,
                appId: params.appId,
                timestamp: params.timestamp,
                nonceStr: params.noncestr,
                signature: params.signature,
                jsApiList: [
                    'onMenuShareTimeline',
                    'onMenuShareAppMessage',
                    'onMenuShareQQ',
                    'onMenuShareQZone',
                ],
            });
            wx.error(function (res) {
                console.log(res);
            });
            wx.ready(function () {
                wx.onMenuShareTimeline(params);
                wx.onMenuShareAppMessage(params);
                wx.onMenuShareQQ(params);
                wx.onMenuShareQZone(params);
            });
        },
        checkUserRole: function (roleId, level) {
            var roles = APP.userModel.userInfo.roles || [];
            var flag = false;
            if (roles.length) {
                _.each(roles, function (item) {
                    if (item.role_id === roleId && item.level >= level) {
                        flag = true;
                    }
                });
            }
            return flag;
        },
        getHashSearch: function () {
            return window.location.hash.split('?')[1] ? '?' + window.location.hash.split('?')[1] : '';
        },
        getQueryString: function (name, data) {
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
            var r = data.match(reg);
            if (r !== null) {
                return decodeURI(r[2]);
            }
            return null;
        },
        getGradeAndSubject: function (grade, subject) {
            var str = '';
            if (grade && subject) {
                var subjectType = _.filter(APP.subjectList, function (label) {
                    if (label.grade === grade && label.subject_name === subject) {
                        return label;
                    }
                });
                str = subjectType[0] && subjectType[0].subject;
            }
            return str;
        },
        replaceParamVal: function (queryString, arg, val) {
            var obj = {};
            _.each(queryString.split('&'), function (p) {
                var keyvalue = p.split('=');
                obj[keyvalue[0]] = keyvalue[1];
            });
            obj[arg] = val;
            var array = _.map(obj, function (p, key) {
                return key + '=' + p;
            });
            return '?' + array.join('&');
        },
        // 固定的导航栏
        HyperlinksAddParams: function ($el, from) {
            var hashSearch = decodeURIComponent(sessionStorage.getItem('hashSearch') || '');
            var $target = $el ? $el.find('.a-with-parameters a') : $('.a-with-parameters a');
            if (hashSearch) {
                if (from) {
                    hashSearch = APP.util.replaceParamVal(hashSearch, 'from', from);
                    if (hashSearch.split('?')) {
                        hashSearch = hashSearch.split('?')[1];
                    }
                }
                $target.each(function () {
                    if (this.hash.split('?')[1]) {
                        this.hash = this.hash.replace(/\?(.+)?/g, '?' + hashSearch);
                    } else {
                        this.hash = this.hash + '?' + hashSearch;
                    }
                });
            } else {
                $target.each(function () {
                    if (this.hash.split('?')[1]) {
                        this.hash = this.hash.replace(/\?(.+)?/g, '');
                    }
                });
            }
        },
        navigate: function (hash, replace) {
            var hashSearch = APP.util.getHashSearch().split('?')[1] ||
                decodeURIComponent(sessionStorage.getItem('hashSearch') || '');
            if (hashSearch) {
                Backbone.history.navigate(hash + '?' + hashSearch, { trigger: replace || false });
            } else {
                Backbone.history.navigate(hash, { trigger: replace || false });
            }
        },
        setPlayUrls: function (url, definition) {
            var str = Number(definition) ? '_' + definition : '';
            var baseUrl = url;
            baseUrl = baseUrl.replace(/^rtmp/, 'http');
            return [
                url,
                baseUrl + str + '.flv',
                baseUrl + str + '.m3u8',
            ];
        },
        clip: function ($target, success, url) {
            const value = ($target && $target.attr('data-msg')) || url;
            const input = document.createElement('input');
            input.setAttribute('readonly', 'readonly');
            input.setAttribute('value', value);
            document.body.appendChild(input);
            input.select();
            input.setSelectionRange(0, 9999);
            if (document.execCommand) {
                document.execCommand('copy');
                // add tips
                if (success) {
                    success();
                }
            }
            document.body.removeChild(input);
        },
        getBlob (url) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(xhr.response);
                    } else {
                        reject(xhr);
                    }
                };
                xhr.send();
            });
        },
        saveAs (blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename);
            } else {
                const link = document.createElement('a');
                const body = document.querySelector('body');

                link.href = URL.createObjectURL(blob);
                link.download = filename;

                // fix Firefox
                link.style.display = 'none';
                body.appendChild(link);

                link.click();
                body.removeChild(link);

                window.URL.revokeObjectURL(link.href);
            }
        },
        summernoteInsertPic: function (context) {
            var ui = $.summernote.ui;

            // create button
            var button = ui.button({
                contents: '<i class="note-icon-picture"/>',
                tooltip: 'picture',
                click: function () {
                    if (window.vm) {
                        // window.vm.$prompt('图片地址', {
                        //     confirmButtonText: '插入',
                        //     cancelButtonText: '取消',
                        // }).then(({ value }) => {
                        //     if (value) {
                        //         context.invoke('editor.insertText', `<img src="${value}">`);
                        //     }
                        // }).catch(() => {
                        // });
                        APP.alert({
                            type: 'input',
                            title: 'dd',
                        });
                    }
                },
            });

            return button.render(); // return button as jquery object
        },
        getSavePdfTemplateStr: function (val, domain) {
            return `<html>
            <head>
              <title>PDF.js viewer</title>
              
              <link rel="stylesheet" href="https://cdn.eduzhixin.com/cdn/pdflib/pdf.js-viewer/viewer.css">
              <script src="https://cdn.eduzhixin.com/cdn/pdflib/pdf.js-viewer/pdf.js"></script> 
              
            </head>
          
            <body>
              <div class="pdfjs">
                <pdfjs-wrapper>
            <div id="outerContainer">

              <div id="sidebarContainer">
                <div id="toolbarSidebar">
                  <div class="splitToolbarButton toggled">
                    <button id="viewThumbnail" class="toolbarButton group toggled" title="Show Thumbnails" tabindex="2" data-l10n-id="thumbs">
                      <span data-l10n-id="thumbs_label">Thumbnails</span>
                    </button>
                    <button id="viewOutline" class="toolbarButton group" title="Show Document Outline" tabindex="3" data-l10n-id="outline">
                      <span data-l10n-id="outline_label">Document Outline</span>
                    </button>
                    <button id="viewAttachments" class="toolbarButton group" title="Show Attachments" tabindex="4" data-l10n-id="attachments">
                      <span data-l10n-id="attachments_label">Attachments</span>
                    </button>
                  </div>
                </div>
                <div id="sidebarContent">
                  <div id="thumbnailView">
                  </div>
                  <div id="outlineView" class="hidden">
                  </div>
                  <div id="attachmentsView" class="hidden">
                  </div>
                </div>
              </div>  <!-- sidebarContainer -->

              <div id="mainContainer">
                <div class="findbar hidden doorHanger hiddenSmallView" id="findbar">
                  <label for="findInput" class="toolbarLabel" data-l10n-id="find_label">Find:</label>
                  <input id="findInput" class="toolbarField" tabindex="91">
                  <div class="splitToolbarButton">
                    <button class="toolbarButton findPrevious" title="" id="findPrevious" tabindex="92" data-l10n-id="find_previous">
                      <span data-l10n-id="find_previous_label">Previous</span>
                    </button>
                    <div class="splitToolbarButtonSeparator"></div>
                    <button class="toolbarButton findNext" title="" id="findNext" tabindex="93" data-l10n-id="find_next">
                      <span data-l10n-id="find_next_label">Next</span>
                    </button>
                  </div>
                  <input type="checkbox" id="findHighlightAll" class="toolbarField">
                  <label for="findHighlightAll" class="toolbarLabel" tabindex="94" data-l10n-id="find_highlight">Highlight all</label>
                  <input type="checkbox" id="findMatchCase" class="toolbarField">
                  <label for="findMatchCase" class="toolbarLabel" tabindex="95" data-l10n-id="find_match_case_label">Match case</label>
                  <span id="findMsg" class="toolbarLabel"></span>
                </div>  <!-- findbar -->

                <div id="secondaryToolbar" class="secondaryToolbar hidden doorHangerRight">
                  <div id="secondaryToolbarButtonContainer">
                    <button id="secondaryPresentationMode" class="secondaryToolbarButton presentationMode visibleLargeView" title="Switch to Presentation Mode" tabindex="51" data-l10n-id="presentation_mode">
                      <span data-l10n-id="presentation_mode_label">Presentation Mode</span>
                    </button>

                    <button id="secondaryOpenFile" class="secondaryToolbarButton openFile visibleLargeView" title="Open File" tabindex="52" data-l10n-id="open_file">
                      <span data-l10n-id="open_file_label">Open</span>
                    </button>

                    <button id="secondaryPrint" class="secondaryToolbarButton print visibleMediumView" title="Print" tabindex="53" data-l10n-id="print">
                      <span data-l10n-id="print_label">Print</span>
                    </button>

                    <button id="secondaryDownload" class="secondaryToolbarButton download visibleMediumView" title="Download" tabindex="54" data-l10n-id="download">
                      <span data-l10n-id="download_label">Download</span>
                    </button>

                    <a href="#" id="secondaryViewBookmark" class="secondaryToolbarButton bookmark visibleSmallView" title="Current view (copy or open in new window)" tabindex="55" data-l10n-id="bookmark">
                      <span data-l10n-id="bookmark_label">Current View</span>
                    </a>

                    <div class="horizontalToolbarSeparator visibleLargeView"></div>

                    <button id="firstPage" class="secondaryToolbarButton firstPage" title="Go to First Page" tabindex="56" data-l10n-id="first_page">
                      <span data-l10n-id="first_page_label">Go to First Page</span>
                    </button>
                    <button id="lastPage" class="secondaryToolbarButton lastPage" title="Go to Last Page" tabindex="57" data-l10n-id="last_page">
                      <span data-l10n-id="last_page_label">Go to Last Page</span>
                    </button>

                    <div class="horizontalToolbarSeparator"></div>

                    <button id="pageRotateCw" class="secondaryToolbarButton rotateCw" title="Rotate Clockwise" tabindex="58" data-l10n-id="page_rotate_cw">
                      <span data-l10n-id="page_rotate_cw_label">Rotate Clockwise</span>
                    </button>
                    <button id="pageRotateCcw" class="secondaryToolbarButton rotateCcw" title="Rotate Counterclockwise" tabindex="59" data-l10n-id="page_rotate_ccw">
                      <span data-l10n-id="page_rotate_ccw_label">Rotate Counterclockwise</span>
                    </button>

                    <div class="horizontalToolbarSeparator"></div>

                    <button id="toggleHandTool" class="secondaryToolbarButton handTool" title="Enable hand tool" tabindex="60" data-l10n-id="hand_tool_enable">
                      <span data-l10n-id="hand_tool_enable_label">Enable hand tool</span>
                    </button>

                    <div class="horizontalToolbarSeparator"></div>

                    <button id="documentProperties" class="secondaryToolbarButton documentProperties" title="Document Properties…" tabindex="61" data-l10n-id="document_properties">
                      <span data-l10n-id="document_properties_label">Document Properties…</span>
                    </button>
                  </div>
                </div>  <!-- secondaryToolbar -->

                <div class="toolbar">
                  <div id="toolbarContainer">
                    <div id="toolbarViewer">
                      <div id="toolbarViewerLeft">
                        <button id="sidebarToggle" class="toolbarButton" title="Toggle Sidebar" tabindex="11" data-l10n-id="toggle_sidebar">
                          <span data-l10n-id="toggle_sidebar_label">Toggle Sidebar</span>
                        </button>
                        <div class="toolbarButtonSpacer"></div>
                        <button id="viewFind" class="toolbarButton group hiddenSmallView" title="Find in Document" tabindex="12" data-l10n-id="findbar">
                          <span data-l10n-id="findbar_label">Find</span>
                        </button>
                        <div class="splitToolbarButton">
                          <button class="toolbarButton pageUp" title="Previous Page" id="previous" tabindex="13" data-l10n-id="previous">
                            <span data-l10n-id="previous_label">Previous</span>
                          </button>
                          <div class="splitToolbarButtonSeparator"></div>
                          <button class="toolbarButton pageDown" title="Next Page" id="next" tabindex="14" data-l10n-id="next">
                            <span data-l10n-id="next_label">Next</span>
                          </button>
                        </div>
                        <label id="pageNumberLabel" class="toolbarLabel" for="pageNumber" data-l10n-id="page_label">Page: </label>
                        <input type="number" id="pageNumber" class="toolbarField pageNumber" value="1" size="4" min="1" tabindex="15">
                        <span id="numPages" class="toolbarLabel"></span>
                      </div>
                      <div id="toolbarViewerRight">
                        <button id="presentationMode" class="toolbarButton presentationMode hiddenLargeView" title="Switch to Presentation Mode" tabindex="31" data-l10n-id="presentation_mode">
                          <span data-l10n-id="presentation_mode_label">Presentation Mode</span>
                        </button>

                        <button id="openFile" class="toolbarButton openFile hiddenLargeView" title="Open File" tabindex="32" data-l10n-id="open_file">
                          <span data-l10n-id="open_file_label">Open</span>
                        </button>

                        <button id="print" class="toolbarButton print hiddenMediumView" title="Print" tabindex="33" data-l10n-id="print">
                          <span data-l10n-id="print_label">Print</span>
                        </button>

                        <button id="download" class="toolbarButton download hiddenMediumView" title="Download" tabindex="34" data-l10n-id="download">
                          <span data-l10n-id="download_label">Download</span>
                        </button>
                        <a href="#" id="viewBookmark" class="toolbarButton bookmark hiddenSmallView" title="Current view (copy or open in new window)" tabindex="35" data-l10n-id="bookmark">
                          <span data-l10n-id="bookmark_label">Current View</span>
                        </a>

                        <div class="verticalToolbarSeparator hiddenSmallView"></div>

                        <button id="secondaryToolbarToggle" class="toolbarButton" title="Tools" tabindex="36" data-l10n-id="tools">
                          <span data-l10n-id="tools_label">Tools</span>
                        </button>
                      </div>
                      <div class="outerCenter">
                        <div class="innerCenter" id="toolbarViewerMiddle">
                          <div class="splitToolbarButton">
                            <button id="zoomOut" class="toolbarButton zoomOut" title="Zoom Out" tabindex="21" data-l10n-id="zoom_out">
                              <span data-l10n-id="zoom_out_label">Zoom Out</span>
                            </button>
                            <div class="splitToolbarButtonSeparator"></div>
                            <button id="zoomIn" class="toolbarButton zoomIn" title="Zoom In" tabindex="22" data-l10n-id="zoom_in">
                              <span data-l10n-id="zoom_in_label">Zoom In</span>
                            </button>
                          </div>
                          <span id="scaleSelectContainer" class="dropdownToolbarButton">
                            <select id="scaleSelect" title="Zoom" tabindex="23" data-l10n-id="zoom">
                              <option id="pageAutoOption" title="" value="auto" selected="selected" data-l10n-id="page_scale_auto">Automatic Zoom</option>
                              <option id="pageActualOption" title="" value="page-actual" data-l10n-id="page_scale_actual">Actual Size</option>
                              <option id="pageFitOption" title="" value="page-fit" data-l10n-id="page_scale_fit">Fit Page</option>
                              <option id="pageWidthOption" title="" value="page-width" data-l10n-id="page_scale_width">Full Width</option>
                              <option id="customScaleOption" title="" value="custom"></option>
                              <option title="" value="0.5" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 50 }'>50%</option>
                              <option title="" value="0.75" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 75 }'>75%</option>
                              <option title="" value="1" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 100 }'>100%</option>
                              <option title="" value="1.25" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 125 }'>125%</option>
                              <option title="" value="1.5" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 150 }'>150%</option>
                              <option title="" value="2" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 200 }'>200%</option>
                              <option title="" value="3" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 300 }'>300%</option>
                              <option title="" value="4" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 400 }'>400%</option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div id="loadingBar">
                      <div class="progress">
                        <div class="glimmer">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <menu type="context" id="viewerContextMenu">
                  <menuitem id="contextFirstPage" label="First Page"
                            data-l10n-id="first_page"></menuitem>
                  <menuitem id="contextLastPage" label="Last Page"
                            data-l10n-id="last_page"></menuitem>
                  <menuitem id="contextPageRotateCw" label="Rotate Clockwise"
                            data-l10n-id="page_rotate_cw"></menuitem>
                  <menuitem id="contextPageRotateCcw" label="Rotate Counter-Clockwise"
                            data-l10n-id="page_rotate_ccw"></menuitem>
                </menu>

                <div id="viewerContainer" tabindex="0">
                  <div id="viewer" class="pdfViewer"></div>
                </div>

                <div id="errorWrapper" hidden='true'>
                  <div id="errorMessageLeft">
                    <span id="errorMessage"></span>
                    <button id="errorShowMore" data-l10n-id="error_more_info">
                      More Information
                    </button>
                    <button id="errorShowLess" data-l10n-id="error_less_info" hidden='true'>
                      Less Information
                    </button>
                  </div>
                  <div id="errorMessageRight">
                    <button id="errorClose" data-l10n-id="error_close">
                      Close
                    </button>
                  </div>
                  <div class="clearBoth"></div>
                  <textarea id="errorMoreInfo" hidden='true' readonly="readonly"></textarea>
                </div>
              </div> <!-- mainContainer -->

              <div id="overlayContainer" class="hidden">
                <div id="passwordOverlay" class="container hidden">
                  <div class="dialog">
                    <div class="row">
                      <p id="passwordText" data-l10n-id="password_label">Enter the password to open this PDF file:</p>
                    </div>
                    <div class="row">
                      <input type="password" id="password" class="toolbarField" />
                    </div>
                    <div class="buttonRow">
                      <button id="passwordCancel" class="overlayButton"><span data-l10n-id="password_cancel">Cancel</span></button>
                      <button id="passwordSubmit" class="overlayButton"><span data-l10n-id="password_ok">OK</span></button>
                    </div>
                  </div>
                </div>
                <div id="documentPropertiesOverlay" class="container hidden">
                  <div class="dialog">
                    <div class="row">
                      <span data-l10n-id="document_properties_file_name">File name:</span> <p id="fileNameField">-</p>
                    </div>
                    <div class="row">
                      <span data-l10n-id="document_properties_file_size">File size:</span> <p id="fileSizeField">-</p>
                    </div>
                    <div class="separator"></div>
                    <div class="row">
                      <span data-l10n-id="document_properties_title">Title:</span> <p id="titleField">-</p>
                    </div>
                    <div class="row">
                      <span data-l10n-id="document_properties_author">Author:</span> <p id="authorField">-</p>
                    </div>
                    <div class="row">
                      <span data-l10n-id="document_properties_subject">Subject:</span> <p id="subjectField">-</p>
                    </div>
                    <div class="row">
                      <span data-l10n-id="document_properties_keywords">Keywords:</span> <p id="keywordsField">-</p>
                    </div>
                    <div class="row">
                      <span data-l10n-id="document_properties_creation_date">Creation Date:</span> <p id="creationDateField">-</p>
                    </div>
                    <div class="row">
                      <span data-l10n-id="document_properties_modification_date">Modification Date:</span> <p id="modificationDateField">-</p>
                    </div>
                    <div class="row">
                      <span data-l10n-id="document_properties_creator">Creator:</span> <p id="creatorField">-</p>
                    </div>
                    <div class="separator"></div>
                    <div class="row">
                      <span data-l10n-id="document_properties_producer">PDF Producer:</span> <p id="producerField">-</p>
                    </div>
                    <div class="row">
                      <span data-l10n-id="document_properties_version">PDF Version:</span> <p id="versionField">-</p>
                    </div>
                    <div class="row">
                      <span data-l10n-id="document_properties_page_count">Page Count:</span> <p id="pageCountField">-</p>
                    </div>
                    <div class="buttonRow">
                      <button id="documentPropertiesClose" class="overlayButton"><span data-l10n-id="document_properties_close">Close</span></button>
                    </div>
                  </div>
                </div>
              </div>  <!-- overlayContainer -->
            </div> <!-- outerContainer -->

            <div id="printContainer"></div>
            <div id="mozPrintCallback-shim" hidden>
              <style>
                @media print {
                  #printContainer div {
                    page-break-after: always;
                    page-break-inside: avoid;
                  }
                }
              </style>
              <style scoped>
                #mozPrintCallback-shim {
                  position: fixed;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  z-index: 9999999;

                  display: block;
                  text-align: center;
                  background-color: rgba(0, 0, 0, 0.5);
                }
                #mozPrintCallback-shim[hidden] {
                  display: none;
                }
                @media print {
                  #mozPrintCallback-shim {
                    display: none;
                  }
                }

                #mozPrintCallback-shim .mozPrintCallback-dialog-box {
                  display: inline-block;
                  margin: -50px auto 0;
                  position: relative;
                  top: 45%;
                  left: 0;
                  min-width: 220px;
                  max-width: 400px;

                  padding: 9px;

                  border: 1px solid hsla(0, 0%, 0%, .5);
                  border-radius: 2px;
                  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

                  background-color: #474747;

                  color: hsl(0, 0%, 85%);
                  font-size: 16px;
                  line-height: 20px;
                }
                #mozPrintCallback-shim .progress-row {
                  clear: both;
                  padding: 1em 0;
                }
                #mozPrintCallback-shim progress {
                  width: 100%;
                }
                #mozPrintCallback-shim .relative-progress {
                  clear: both;
                  float: right;
                }
                #mozPrintCallback-shim .progress-actions {
                  clear: both;
                }
              </style>
            </div>
          </pdfjs-wrapper>

              </div>
            <script>
    function ajax (options) {
      var defaults = {
        type: 'get',
        url: '',
        data: {},
        header: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'withCredentials': true
        },
        success: function () {},
        error: function () {}
      }

      // 使用options对象中的属性覆盖defaults对象中的属性
      Object.assign(defaults, options)

      // 创建ajax对象
      var xhr = new XMLHttpRequest()
      // 拼接请求参数的变量
      var params = ''
      // 循环用户传递进来的对象格式参数
      for (var attr in defaults.data) {
        // 将参数转换为字符串格式
        params += attr + '=' + defaults.data[attr] + '&'
      }
      // 将参数最后面的&截取掉
      // 将截取的结果重新赋值给params变量
      params = params.substr(0, params.length - 1)

      // 判断请求方式
      if (defaults.type === 'get') {
        defaults.url = defaults.url + '?' + params
      }

      // 配置ajax对象
      xhr.open(defaults.type, defaults.url)
      xhr.setRequestHeader('X-Requested-With','XMLHttpRequest')
      xhr.withCredentials = true
      // 如果请求方式为post
      if (defaults.type === 'post') {
        // 用户希望的向服务器端传递的请求参数的类型
        var contentType = defaults.header['Content-Type']
        // 设置请求参数格式的类型
        xhr.setRequestHeader('Content-Type', contentType)
        
        
        // 判断用户希望的请求参数格式的类型
        // 如果类型为json
        if (contentType === 'application/json') {
          // 向服务器端传递json数据格式的参数
          xhr.send(JSON.stringify(defaults.data))
        } else {
          // 向服务器端传递普通类型的请求参数
          xhr.send(params)
        }
      } else {
        // 发送请求
        xhr.send()
      }
      // 监听xhr对象下面的onload事件
      // 当xhr对象接收完响应数据后触发
      xhr.onload = function () {
        // xhr.getResponseHeader()
        // 获取响应头中的数据
        var contentType = xhr.getResponseHeader('Content-Type')
        // 服务器端返回的数据
        var responseText = xhr.responseText

        // 如果响应类型中包含applicaition/json
        if (contentType.includes('application/json')) {
          // 将json字符串转换为json对象
          responseText = JSON.parse(responseText)
        }

        // 当http状态码等于200的时候
        if (xhr.status === 200) {
          // 请求成功 调用处理成功情况的函数
          defaults.success(responseText, xhr)
        } else {
          // 请求失败 调用处理失败情况的函数
          defaults.error(responseText, xhr)
        }
      }
    }
    function success (data) {
        console.log(data)
        window.PDFJS.webViewerLoad(data.pdf_url)
      }
    function getParams() {
      var arr = location.search.split('=');
      var id = arr[arr.length - 1];
      return id;

    }
    ajax({
      type: 'get',
      // 请求地址
      url: '${domain}/v1/Subclass/getCoursewarePdf',
      data: {subclass_id: getParams()},
      success: success
    })
  </script>
            </body>
          </html>`;
        },
        getPdfTemplateStr: function (url) {
            return `<html dir="ltr">
                <head>
                    <meta charset="utf-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
                    <title>PDF.js viewer</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf_viewer.min.css">
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf.min.js"
                        integrity="sha512-FbzTWNUXsD1HkMnfODpqj77B8pWe5eG9MaVtaUyZQGe8FaeQg1sNKtwMWk9USkHFu3ub8dg5PSE24s4JJpJQcQ=="
                        crossorigin="anonymous" referrerpolicy="no-referrer"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf_viewer.min.js"></script>
                    <style>
                        #viewerContainer {
                            position: absolute;
                            overflow: auto;
                            width: 100%;
                            inset: 2rem 0 3rem;
                        }
                        #viewerContainer canvas {
                            margin: auto;
                            display: block;
                        }
                    </style>
                </head>
                <body>
                    <div id="viewerContainer">
                        <div id="viewer" class="pdfViewer"></div>
                    </div>
                    <script src="https://cdn.eduzhixin.com/cdn/pdflib/zhixin_pdf.js"></script>
                    <script>
                        setTimeout(() => {
                            if(document.readyState === 'complete') {
                                window.PDFViewerApplication.initUI();
                            } else {
                                document.addEventListener('DOMContentLoaded', function () {
                                    window.PDFViewerApplication.initUI();
                                }, true);
                            }
                        }, 100)
                        // window.PDFViewerApplication.initUI();
                        window.PDFViewerApplication.open({ url: '${url}',});
                    </script>
                </body>

                </html>`;
        },

    };
    return Util;
    // What we return here will be used by other modules
});
