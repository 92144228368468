<template>
    <el-dialog
        class="upload-image-dialog"
        title="上传图片"
        :visible.sync="uploadImageVisible"
        :close-on-click-modal="false"
        :before-close="cancelUpload">
        图片地址：<el-input v-model="imageUrl" clearable placeholder="请输入图片地址,比如: https://static.eduzhixin.com/图片路径/*.png"></el-input>
        或者
        上传图片：
        <ZhiXinImageUpload
            class="img-area"
            :action-params="{dir: dir}"
            :limit="1"
            accept=".png,.jpg,.jpeg"
            v-model="imageUrlList"/>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelUpload">取 消</el-button>
            <el-button type="primary" @click="confirmUpload" v-prevent-db-click>确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import ZhiXinImageUpload from '@/components/image-upload/Main.vue';
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        dir: {
            type: String,
            default: '',
        },
    },
    components: {
        ZhiXinImageUpload,
    },
    watch: {
        show (val) {
            if (val) {
                this.uploadImageVisible = true;
                this.imageUrl = '';
                this.imageUrlList = [];
            }
        },
    },
    data () {
        return {
            uploadImageVisible: false,
            imageUrl: '',
            imageUrlList: [],
        };
    },
    methods: {
        // 取消上传
        cancelUpload () {
            this.uploadImageVisible = false;
            this.imageUrl = '';
            this.imageUrlList = [];
            this.$emit('cancel');
        },

        // 确认上传
        confirmUpload () {
            const url = this.imageUrl || this.imageUrlList[0]?.response?.pic_url || '';
            if (this.imageUrl && this.imageUrlList.length) {
                this.$message.error('只能上传一个图片');
                return;
            }
            if (!this.imageUrl && this.imageUrlList.length === 0) {
                this.$message.error('请输入图片地址或者上传图片');
                return;
            }
            if (url.indexOf('eduzhixin.com') === -1) {
                this.$message({
                    type: 'error',
                    message: '请输入正确链接',
                });
                return;
            }
            this.$emit('confirm', url);
            this.uploadImageVisible = false;
            this.imageUrl = '';
            this.imageUrlList = [];
        },
    },
};
</script>

<style lang="scss" scoped>
.upload-image-dialog {
    /deep/ .el-dialog__body {
        padding: 10px 20px;
    }
}
</style>
