<template>
    <el-checkbox-group
        :value="value"
        @change="(val) => $emit('input', val)"
        v-bind="$attrs"
        v-on="$listeners">
        <el-checkbox
            v-for="{text, ...attrs} in options"
            :key="attrs.label"
            v-bind="attrs"
            >
            {{ text }}
        </el-checkbox>
    </el-checkbox-group>
</template>

<script>
export default {
    props: {
        value: {
            default: () => [],
            type: Array,
        },
        options: {
            default: () => [],
            type: Array,
        },
    },
    computed: {},
    data () {
        return { };
    },
    mounted () { },
};
</script>

<style>

</style>
