<template>
   <el-upload
        :file-list="value"
        :auto-upload="auto-upload"
        :on-success="onUploadSuccess"
        :on-change="onChange"
        :on-remove="onRemove"
        v-bind="$attrs"
        v-on="$listeners">
        <el-button v-if="uploadBtn" size="small" type="primary">{{ uploadBtn }}</el-button>
        <div slot="tip" class="el-upload__tip">{{ tips }}</div>
    </el-upload>
</template>

<script>
export default {
    props: {
        value: {
            default: () => [],
        },
        'auto-upload': {
            type: Boolean,
            default: true,
        },
        tips: {
            type: String,
            default: '',
        },
        uploadBtn: {
            type: String,
            default: '',
        },
        limit: {
            type: Number,
            default: null,
        },
    },
    watch: {
        value: {
            handler () {
                console.log(this.value);
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {},
    data () {
        return {};
    },
    mounted () {},
    methods: {
        onUploadSuccess (res, file, fileList) {
            if (this['auto-upload']) {
                if (res.code === 1) {
                    this.$emit('input', [...this.value, res.data]);
                } else {
                    this.$message.error(res.message);
                }
            }
        },
        onChange (file, fileList) {
            if (!this['auto-upload']) {
                if (this.limit && fileList.length >= this.limit) {
                    this.value.pop();
                    this.$emit('input', [...this.value, file]);
                } else {
                    this.$emit('input', [...this.value, file]);
                }
            }
        },
        onRemove (file, fileList) {
            this.$emit('input', [...fileList]);
        },
    },
};
</script>

<style lang="scss" scoped>
    /deep/ .el-upload__input {
        display: none;
    }
</style>
