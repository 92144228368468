import Vue from 'vue';
import ElementUi from 'element-ui';
import VueForm from '@lljj/vue-json-schema-form';
import PageEngine from '@/components/page-engine/Index';
import FormEngine from '@/components/form-engine/Index';
import CommonTable from '@/components/common-table/Index';
import ImageUpload from '@/components/image-upload/Main';
// import 'element-ui/lib/theme-chalk/index.css';
import '../public/stylesheets/element-variables.scss';
import VueRoutes from '@/route/vue-routes';
import notFound from '@/container/NotFound';
import filters from './util/filters';
import '@/components/directive';
import { getSubjectList } from '@/api/dictionary.js';
window.admApiVersion = '/v1';

Vue.use(ElementUi);
Vue.component('PageEngine', PageEngine);
Vue.component('FormEngine', FormEngine);
Vue.component('CommonTable', CommonTable);
Vue.component('ZxForm', VueForm);
Vue.component('ImageUpload', ImageUpload);

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});
const vm = new Vue({
    el: '#vue_container',
    components: {
        notFound: notFound,
        ...VueRoutes,
    },
    data: {
        currentRoute: window.location.hash,
        excludeComponent: [],
        menuList: [],
        isReady: false,
    },
    mounted () {
        try {
            this.menuList = JSON.parse(localStorage.getItem('menuList'));
        } catch (error) {
            this.menuList = [];
        }
    },
    created () {
        this.getSubjectDictionary();
        window.onhashchange = () => {
            this.currentRoute = window.location.hash;
            this.excludeComponent = [];
            try {
                this.menuList = JSON.parse(localStorage.getItem('menuList'));
                const routeName = this.currentRoute.replace('#', '').split('?')[0].split('/')[0];

                if (!this.isMenu(this.menuList)) {
                    this.excludeComponent = [routeName];
                    this.currentRoute = '';
                    this.$nextTick(() => {
                        this.currentRoute = window.location.hash;
                    });
                }
            } catch (err) {
                console.log(err);
            }
        };
        window.addEventListener('message', (event) => {
            const params = event.data || {};
            if (params.from === 'menuHistory') {
                const routeName = this.currentRoute.replace('#', '').split('?')[0].split('/')[0];
                const h = VueRoutes[routeName];

                if (this.currentRoute === params.url) {
                    // 当前页面刷新
                    if (h) {
                        // vue页面
                        const compName = this.ViewComponent;
                        this.excludeComponent = [compName];
                        this.currentRoute = '';
                        this.$nextTick(() => {
                            this.currentRoute = window.location.hash;
                        });
                    } else {
                        // backbone页面
                        Backbone.history.loadUrl(Backbone.history.fragment);
                    }
                } else {
                    // 切换页面刷新
                    this.excludeComponent = [params.url.replace('#', '').split('?')[0].split('/')[0]];
                    window.location.href = params.url;
                }
            }
        });
    },
    methods: {
        isMenu (list) {
            let res = false;
            const routeName = this.currentRoute.replace('#', '').split('?')[0].split('/')[0];
            for (let item of list) {
                if (item.children) {
                    res = this.isMenu(item.children);
                    if (res) {
                        break;
                    }
                } else {
                    if (item.uri === routeName) {
                        res = true;
                        break;
                    }
                }
            }
            return res;
        },
        // 获取学科字典
        getSubjectDictionary () {
            getSubjectList().then(res => {
                const { code, subject } = res;
                if (code === 1) {
                    this.isReady = true;
                    APP.subjectList = {};
                    APP.codes.GRADE_TYPE = {};
                    APP.codes.SUBJECT_TYPE = {};
                    APP.codes.SEARCH_SUBJECT_TYPE = {};
                    subject.forEach(item => {
                        APP.subjectList[item.subject] = item;
                        APP.codes.GRADE_TYPE[item.grade] = item.grade;
                        APP.codes.SUBJECT_TYPE[item.subject_name] = item.subject_name;
                        APP.codes.SEARCH_SUBJECT_TYPE[item.subject_name] = item.subject_name;
                        APP.codes.EN_ZH_SUBJECT_TYPE[item.subject] = item.grade + item.subject_name;
                    });
                    delete APP.codes.SEARCH_SUBJECT_TYPE['自招'];
                }
            });
        },
    },
    computed: {
        // 匹配上的组件名称
        ViewComponent () {
            const routeName = this.currentRoute.replace('#', '').split('?')[0].split('/')[0];
            const h = VueRoutes[routeName];

            if (h) {
                $('#vue_primary_content').removeClass('hide').siblings('#primary_content').addClass('hide');
                return routeName;
            }
            return 'notFound';
        },
    },
    // render (h) {
    //     return h(this.ViewComponent);
    // },
});
export {
    vm,
};
Vue.config.devtools = true;
window.vm = vm;
