<template>
    <div :id="id"></div>
</template>
<script>
import $ from 'jquery';
import 'summernote';
import { request } from '@/api/request';
export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        height: {
            type: Number,
            default: 300,
        },
        dir: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            editor: null,
            id: new Date().getTime() + '',
        };
    },
    watch: {
        value: {
            handler (val) {
                if (this.editor) {
                    const currentVal = this.getContent();
                    if (currentVal !== val) {
                        this.editor.summernote('code', val);
                    }
                }
            },
            immediate: true,
        },
    },
    beforeMount () {
        this.id = this.id + this._uid + '';
    },
    mounted () {
        this.editor = $('#' + this.id).summernote({
            height: this.height,
            lang: 'zh-CN',
            tabsize: 4,
            prettifyHtml: true,
            fontNames: [
                'Microsoft YaHei',
            ],
            defaultFontName: 'Microsoft YaHei',
            callbacks: {
                onChange: (contents, $editable) => {
                    this.$emit('input', contents);
                },
                onImageUpload: (files) => {
                    this.beforeUploadImage(files);
                },
                onImageLinkInsert: (url) => {
                    this.beforeImageLinkInsert(url);
                },
            },
        });
        this.editor.summernote('code', this.value);
    },
    methods: {
        getContent () {
            return this.editor.summernote('code') || '';
        },
        getText () {
            const content = this.editor.summernote('code') || '';
            const dom = document.createElement('div');
            dom.innerHTML = content;
            return dom.innerText;
        },
        // 上传图片
        async beforeUploadImage (files) {
            let params = new window.FormData();
            params.append('file', files[0]);
            if (this.dir) {
                params.append('dir', this.dir);
            }
            const { code, msg, pic_url } = await request('/v1/admin/Classcenter/uploadAd', 'POST', {
                data: params,
                processData: false,
                contentType: false,
            });
            if (code === 1) {
                const imgNode = $('<img>').attr({ src: pic_url });
                this.editor.summernote('insertNode', imgNode[0]);
            } else {
                this.$message({
                    type: 'error',
                    message: msg,
                });
            }
        },
        // 上传图片链接
        beforeImageLinkInsert (url) {
            // 本地图片链接和base64禁止上传
            if (url.startsWith('file://') || url.startsWith('data:image')) {
                this.$message({
                    type: 'error',
                    message: '请输入正确链接',
                });
                return;
            }
            const imgNode = $('<img>').attr({ src: url });
            this.editor.summernote('insertNode', imgNode[0]);
        },
    },
};
</script>

<style>

</style>
