<!-- 页面引擎 -->
<template>
  <div class="page-container">
    <div class="page-content">
        <div class="page-header__zx">
            <h3>
                {{ title }}
                <i v-if="addFn" class="page-header__add glyphicon glyphicon-plus-sign" @click="addFn"></i>
                <div class="page-header__others">
                    <slot name="headerBtns"></slot>
                </div>
            </h3>
            <slot name="headerOther"></slot>
        </div>
        <div class="page-filter__zx"><slot name="filter"></slot></div>
        <div class="page-table__zx"><slot name="table" v-bind:tableHeight="tableHeight"></slot></div>
        <div class="page-pagination__zx"><slot name="pagination"></slot></div>
    </div>
    <slot></slot>
  </div>
</template>
<script>

export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        addFn: {
            type: Function,
        },
    },
    components: {

    },
    data () {
        return {
            tableHeight: null,
        };
    },
    mounted () {
        this.initStyle();
        window.addEventListener('resize', () => {
            this.initStyle();
        });
    },
    computed: {},
    methods: {
        initStyle () {
            const pageNode = this.$vnode.elm;
            const docHeight = document.documentElement.clientHeight || document.body.clientHeight;
            const headerHeight = pageNode.getElementsByClassName('page-header__zx')[0].clientHeight || 0;
            const filterHeight = pageNode.getElementsByClassName('page-filter__zx')[0].clientHeight || 0;
            const paginationHeight = pageNode.getElementsByClassName('page-pagination__zx')[0].clientHeight || 0;
            const tablePaddingHeight = 20;
            const pageOutsideHeight = 120;
            const historyBoxHeight = 64;
            this.tableHeight = docHeight - pageOutsideHeight - tablePaddingHeight - headerHeight - filterHeight - paginationHeight - historyBoxHeight;
        },
    },
    updated () {
        this.initStyle();
    },
};
</script>
<style lang="scss" scoped>
.page-container {
  padding: 0 15px;
  .page-content {
    background-color: #fff;
    .page-header__zx {
        position: relative;
        h3{
            font-size: 18px;
            padding: 0;
        }
        color: #00C7BF !important;
        padding: 10px 15px;
        border-bottom: 1px solid #EBEEF5;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        .page-header__add {
            cursor: pointer;
            vertical-align: top;
        }
        .page-header__others {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            float: right;
            .el-button {
                margin-left: 10px;
            }
        }
    }
    .page-filter__zx {
        padding: 10px 20px 0 15px;
    }
    .page-table__zx {
        padding: 10px 15px;
    }
    .page-pagination__zx {
        padding: 0 10px 10px 10px;
        text-align: center;
    }
  }
}
</style>
