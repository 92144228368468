define([
    'backbone',
], function () {
    var user = Backbone.Model.extend({
        initialize: function () {

        },
        defaults: {},
    });

    var Users = Backbone.Collection.extend({
        initialize: function () {
            this.options = {};
            this.page = 1;
            this.pageCount = 10;
            this.$userinfoDeferred = $.Deferred();
            this.$adminListDeferred = $.Deferred();
            this.$getSubjectListDeferred = $.Deferred();
            if (!APP.userModel) {
                this.processUserRole();
            }
        },
        model: user,
        fetch: function (option, page) {
            var _this = this;
            $.ajax({
                type: 'POST',
                url: '/admin/User/findUsers/' + (page || this.page) + '/',
                data: option || this.options,
                dataType: 'JSON',
                success: function (data) {
                    var collection = [];
                    _.each(data.user, function (element) {
                        element.register_at_time = _this.convertTime(element.register_at);
                        element.confirm_at = _this.convertTime(element.confirm_at);
                        collection.push(element);
                    });
                    _this.reset(collection);
                    _this.totalPage = data.totalPage;
                    _this.page = page || _this.page;
                    _this.trigger('fetched');
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        fetchVip: function (data) {
            var _this = this;
            if (data && data.product_type === 'LONG_CLASS_VIP_USER') {
                this.getNewVipUsers(data);
                return false;
            }

            $.ajax({
                type: 'GET',
                url: '/admin/Class/getVipUsers',
                dataType: 'JSON',
                data: {
                    page: data.current_page || 1,
                },
                success: function (data) {
                    var class_vip_users_c = [];
                    _.each(data.vip_users.data, function (item) {
                        if (item) {
                            class_vip_users_c.push({
                                mobile: item,
                            });
                        }
                    });
                    if (class_vip_users_c.length) {
                        data.class_vip_users_c = class_vip_users_c || [];
                    } else {
                        data.class_vip_users_c = [];
                    }
                    if (data.items) {
                        data.longclass_vip_users = data.items;
                    }
                    _this.reset([]);
                    _this.trigger('fetchedVip', {
                        campclass_vip_users: class_vip_users_c,
                        page: data.vip_users.page,
                        totalPage: data.vip_users.total_pages,
                    });
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        getNewVipUsers: function (params) {
            var _this = this;
            params = params || { current_page: 1 };
            $.ajax({
                type: 'GET',
                url: '/admin/Class/getNewVipUsers',
                dataType: 'JSON',
                data: params,
                success: function (data) {
                    if (data.items && params.product_type === 'LONG_CLASS_VIP_USER') {
                        data.longclass_vip_users = data.items;
                    }
                    if (data.items && params.product_type === 'OFFLINE_CLASS_VIP_USER') {
                        data.campclass_vip_users = data.items;
                    }
                    _this.reset([]);
                    data.page = data.current;
                    data.totalPage = data.total_pages;
                    _this.trigger('fetchedVip', data);
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        addVip: function (data) {
            var _this = this;
            var product_type = data.product_type;
            var url = '/admin/Class/createVipUser';
            if (data.product_type === 'LONG_CLASS_VIP_USER') {
                this.addNewVip(data);
                return false;
            }
            $.ajax({
                type: 'POST',
                url: url,
                dataType: 'JSON',
                data: data,
                success: function (data) {
                    if (data.result === 1) {
                        window.alert('增加成功');
                        _this.fetchVip({ product_type: product_type });
                    } else {
                        window.alert(data.msg);
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        addNewVip: function (data) {
            var _this = this;
            var url = '/admin/Class/createNewVipUser';
            var product_type = data.product_type;
            $.ajax({
                type: 'POST',
                url: url,
                dataType: 'JSON',
                data: data,
                success: function (data) {
                    if (data.result === 1) {
                        window.alert('增加成功');
                        _this.fetchVip({ product_type: product_type });
                    } else {
                        window.alert(data.msg);
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        getVipPreDay: function (data) {
            var _this = this;
            $.ajax({
                type: 'POST',
                url: data.url,
                dataType: 'JSON',
                success: function (data) {
                    if (data.result === 1) {
                        _this.trigger('getvippredayed', data.pre_day);
                    } else {
                        window.alert(data.msg);
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        deleteVip: function (data) {
            var _this = this;
            var product_type = data.product_type;
            var page = data.current_page;
            var url = '/admin/Class/deleteVipUser/';
            if (data.product_type === 'LONG_CLASS_VIP_USER') {
                this.deleteNewVipUser(data);
                return false;
            }
            $.ajax({
                type: 'POST',
                url: url,
                dataType: 'JSON',
                data: data,
                beforeSend: function () {
                    if (!window.confirm('要删除吗？')) {
                        return false;
                    }
                },
                success: function (data) {
                    if (data.result === 1) {
                        window.alert('删除成功');
                        _this.fetchVip({ product_type: product_type, current_page: page });
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        deleteNewVipUser: function (data) {
            var _this = this;
            var url = '/admin/Class/deleteNewVipUser';
            var product_type = data.product_type;
            $.ajax({
                type: 'POST',
                url: url,
                dataType: 'JSON',
                data: data,
                beforeSend: function () {
                    if (!window.confirm('要删除吗？')) {
                        return false;
                    }
                },
                success: function (data) {
                    if (data.result === 1) {
                        window.alert('删除成功');
                        _this.fetchVip({ product_type: product_type });
                    } else {
                        window.alert(data.msg);
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        changeRole: function (role_ids, userId) {
            var _this = this;
            $.ajax({
                type: 'POST',
                url: '/admin/UserRole/update',
                dataType: 'JSON',
                data: {
                    role_ids: role_ids,
                    user_id: userId,
                },
                success: function (data) {
                    if (data.result === 1) {
                        window.alert('更新成功');
                        _this.getAdminList();
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        getAdminList: function () {
            var _this = this;
            $.ajax({
                type: 'POST',
                url: '/admin/UserRole/get',
                dataType: 'JSON',
                success: function (data) {
                    _this.adminList = data.users_roles;
                    _this.set('adminList', this.adminList);
                    _this.trigger('updateAdmin');
                    _this.$adminListDeferred.resolve();
                },
                error: function (data) {
                    const hash = location.hash.split('?')[0].slice(1);
                    if (hash === 'login') {
                        return;
                    }
                    window.alert(data.responseText);
                },
            });
        },
        getFollowUpist: function () {
            var _this = this;
            $.ajax({
                type: 'POST',
                url: '/admin/UserRole/getFollowUp',
                dataType: 'JSON',
                success: function (data) {
                    _this.adminList = data.users_roles;
                    _this.set('adminList', this.adminList);
                    _this.trigger('updateAdmin');
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        setAdmin: function (userId) {
            var _this = this;
            $.ajax({
                type: 'POST',
                url: '/admin/User/createAdmin',
                dataType: 'JSON',
                data: {
                    user_id: userId,
                },
                beforeSend: function () {
                    if (!window.confirm('确定要设为管理员？')) {
                        return false;
                    }
                },
                success: function (data) {
                    if (data.result === 1) {
                        window.alert('设置成功');
                        _this.trigger('setAdmin');
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        removeAdmin: function (userId) {
            var _this = this;
            $.ajax({
                type: 'POST',
                url: '/admin/User/deleteAdmin/' + userId,
                dataType: 'JSON',
                beforeSend: function () {
                    if (!window.confirm('确定要删除吗？')) {
                        return false;
                    }
                },
                success: function (data) {
                    if (data.result === 1) {
                        window.alert('解除成功');
                        _this.trigger('removeAdmin');
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        setVipDays: function (data) {
            // var _this = this;
            $.ajax({
                type: 'POST',
                url: '/admin/Class/createVipDay/',
                dataType: 'JSON',
                data: data,
                success: function (data) {
                    if (data.result === 1) {
                        window.alert('设置成功');
                    } else {
                        window.alert(data.msg);
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        convertTime: function (second) {
            // var date = new Date(second*1000).toISOString().slice(0,19).replace(/T/g,' ');
            if (second === 0) {
                return '无';
            } else {
                var seconds = Number(second) * 1000 + 28800000;
                var date = new Date(seconds);
                return date.toISOString().slice(0, 10);
            }

            // return date;
        },
        getUserInfo: function () {
            var _this = this;
            $.ajax({
                type: 'GET',
                url: '/User/getInfo/',
                dataType: 'JSON',
                success: function (data) {
                    if (data.result === 1) {
                        _this.userInfo = data;
                        _this.$userinfoDeferred.resolve();
                    } else {
                        window.alert(data.msg);
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        getSubjectList: function () {
            var _this = this;
            $.ajax({
                type: 'GET',
                url: '/Subject/getList',
                dataType: 'JSON',
                success: function (data) {
                    if (data.result === 1) {
                        APP.subjectList = {};
                        APP.codes.GRADE_TYPE = {};
                        APP.codes.SUBJECT_TYPE = {};
                        APP.codes.SEARCH_SUBJECT_TYPE = {};
                        _.each(data.subject, function (item) {
                            APP.subjectList[item.subject] = item;
                            APP.codes.GRADE_TYPE[item.grade] = item.grade;
                            APP.codes.SUBJECT_TYPE[item.subject_name] = item.subject_name;
                            APP.codes.SEARCH_SUBJECT_TYPE[item.subject_name] = item.subject_name;
                            APP.codes.EN_ZH_SUBJECT_TYPE[item.subject] = item.grade + item.subject_name;
                        });
                        delete APP.codes.SEARCH_SUBJECT_TYPE['自招'];
                        _this.$getSubjectListDeferred.resolve();
                    } else {
                        window.alert(data.msg);
                    }
                },
                error: function (data) {
                    window.alert(data.responseText);
                },
            });
        },
        processUserRole: function () {
            var _this = this;
            this.getUserInfo();
            this.getAdminList();
            this.getSubjectList();
            $.when(this.$userinfoDeferred, this.$adminListDeferred, this.$getSubjectListDeferred).then(function () {
                var roles = [];
                roles = _.filter(_this.adminList, { user_id: _this.userInfo.user_id });
                _this.userInfo.roles = roles;
                Backbone.history.start();
            });
        },
    });
    return Users;
});
