import 'static/css/admin/AdminLTE.min.css';
import 'static/css/admin/_all-skins.css';
import 'static/css/zhixin-bootstrap.css';
import 'static/css/admin/jquery.dataTables.min.css';
import 'static/css/custom-font.css';
import 'static/css/admin.css';
import '@/assets/scss/admins.scss';
import 'static/css/libs/sweetalert.min.css';
import 'codemirror/lib/codemirror.css';
import 'summernote/dist/summernote.css';
import 'static/css/libs/formValidation.min.css';
import 'static/css/libs/bootstrap/bootstrap-datepicker.standalone.min.css';
import 'static/css/libs/bootstrap/bootstrap-datetimepicker.min.css';
import 'static/css/libs/bootstrap/bootstrap-slider.min.css';
import 'static/css/libs/bootstrap/bootstrap.icon.min.css';
import 'static/fonts/font-awesome/css/font-awesome.min.css';
import 'static/css/libs/fixedColumns.dataTables.min.css';
