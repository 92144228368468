import moment from '@/plugins/moment';

function formatDate (times, format = 'YYYY.MM.DD') {
    return moment(times * 1000).format(format);
}

function formatDateYMDHMS (times) {
    return moment(times * 1000).format('YYYY.MM.DD HH:mm:ss');
}

function formatDateYMDHM (times) {
    return moment(times * 1000).format('YYYY.MM.DD HH:mm');
}
function handleTime (time) {
    // const day = Math.floor(time / 86400);
    let hour = Math.floor((time / 3600) % 24);
    let min = Math.floor((time / 60) % 60);
    let sec = Math.floor(time % 60);
    hour = hour < 10 ? '0' + hour : hour;
    min = min < 10 ? '0' + min : min;
    sec = sec < 10 ? '0' + sec : sec;
    return hour + ':' + min + ':' + sec;
}
function discountInfo (row) {
    let html = '';
    const ladderPricings = row.ladder_pricings || [];
    ladderPricings.forEach((item) => {
        html += `, ${item.class_number} 个课程组${row.discount_type === 1
            ? `打 ${item.discount_val / 10} 折`
            : `直减 ${Number((item.discount_val / 100).toFixed(2))}`
        }`;
    });
    return html.substring(1);
}
function deleteEmptyParams (params) {
    for (var key in params) {
        if (!params[key]) {
            delete params[key];
        }
    }
    return params;
}
function handleCurrencyType (params) {
    if (params === 'PROTON') {
        return '质子';
    } else if (params === 'ATOM') {
        return '原子';
    } else {
        return '';
    }
}
function jsToFormData (config) { // 对象转formdata格式
    let formData = new FormData();
    let obj = config;
    let arrayKey = config.arrayKey;
    for (var i in obj) {
        if (Array.isArray(obj[i])) {
            obj[i].map(item => {
                if (!arrayKey) {
                    formData.append(i, item);
                } else {
                    formData.append(i + '[]', item);
                }
            });
        } else if (obj[i] instanceof FileList) {
            // filelist 文件类型的处理
            for (var fileItem = 0; fileItem < obj[i].length; fileItem++) {
                if (!arrayKey) {
                    formData.append(i, obj[i].item(fileItem));
                } else {
                    formData.append(i + '[]', obj[i].item(fileItem));
                }
            }
        } else {
            formData.append(i, obj[i]);
        }
    }
    return formData;
}

export default {
    formatDate,
    handleTime,
    discountInfo,
    formatDateYMDHM,
    formatDateYMDHMS,
    deleteEmptyParams,
    handleCurrencyType,
    jsToFormData,
};
