import * as UsersModel from 'admin/models/UsersModel';
import * as Codes from 'admin/common/index';
import {
    RoutesFunc,
    Routes,
} from '@/route/routes';
import 'accordion';
import 'datepicker';
import 'bootstrap';
import 'formvalidation';
import 'cookie';
import 'jquerypage';

APP.GardenRouter = Backbone.Router.extend({
    initialize: function () {
        this.listenTo(this, 'route', function (route) {
            $('.datetimepicker.dropdown-menu').remove();
            var sidebar = $('.sidebar-menu');
            var hash = location.hash.split('?')[0];
            var $curRout = sidebar.find('a[href="' + hash + '"]');
            if (hash && $curRout.length) {
                $curRout.parents('.treeview').addClass('active');
                var $li = $('.breadcrumb li');
                $li.eq(1).text(
                    $curRout.parents('.treeview').find('.text').text(),
                );
                $li.eq(2).text($curRout.text());
            }
        });
        APP.codes = Codes;
    },
    routes: Routes,
    ...RoutesFunc,
});

var initialize = function () {
    APP.userModel = new UsersModel();
    APP.GardenRouter = new APP.GardenRouter({});
    // Backbone.history.start();
};

export {
    initialize,
};
