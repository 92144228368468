import { ZT_DOMAIN } from '@/constants';
import mapping from './api-mapping';
const baseUrlLogin = process.env.PASSPORT_URL;
const gatewayUrl = process.env.GATEWAY_URL;
const baseUrl = '/v1';
const baseUrlV2 = '/v2';
const baseUrlStatic = '/static';
const mockUrl = '/mock/';
APP.env = {
    ZT_DOMAIN: ZT_DOMAIN,
};
const passportUris = ['/Auth/access', '/Logout/logout', '/Sms/getBg', '/Register/resetPassword'];
const gatewayUrls = ['/offline/getList'];
const isdev = process.env.NODE_ENV === 'development';
console.log(process.env.NODE_ENV);
const request = function request (url, method, params = {
    type: 'GET',
    url: '',
    dataType: 'JSON',
    data: {},
    headers: {
        'Access-Control-Allow-Credentials': true,
    },
}, prefix = '') {
    let ajaxBaseUrl = '';
    if (typeof url === 'object') {
        throw Error('url excepted be a string');
    }
    if (typeof method === 'object') {
        throw Error('url excepted be a string');
    }
    params.type = method;
    params.url = url;
    params.dataType = 'JSON';
    params.async = params.async || true;
    const isPassport = passportUris.includes(params.url);
    const isGateway = gatewayUrls.includes(params.url);
    // console.log(params.url);
    if (prefix) {
        if (isdev) {
            ajaxBaseUrl = prefix;
        } else {
            ajaxBaseUrl = mapping[prefix];
        }
    } else {
        if (isPassport) {
            ajaxBaseUrl = isdev ? prefix : baseUrlLogin;
        } else if (isGateway) {
            ajaxBaseUrl = gatewayUrl + '/v1';
        } else if (params.url.indexOf(baseUrlStatic) === 0) {
            ajaxBaseUrl = baseUrlStatic;
        } else if (params.url.indexOf('http://') === 0 || params.url.indexOf('https://') === 0 || params.url.indexOf('//') === 0) {
            ajaxBaseUrl = '';
        } else if (params.url.indexOf(mockUrl) > -1) {
            ajaxBaseUrl = '';
        } else if (params.url.indexOf(baseUrl) === 0 || params.url.indexOf(baseUrlV2) === 0) {
            ajaxBaseUrl = '';
        } else {
            ajaxBaseUrl = baseUrl;
        }
    }

    // console.log(`${ajaxBaseUrl}${url}`);
    const {
        type,
        data,
        dataType,
        headers,
        processData,
        contentType,
        async,
    } = params;
    return $.ajax({
        type,
        url: `${ajaxBaseUrl}${url}`,
        dataType,
        data,
        processData,
        async,
        contentType,
        xhrFields: {
            withCredentials: true,
        },
        crossDomain: true,
        headers: {
            // 接口返回的json 不是 html
            'X-Requested-With': 'XMLHttpRequest',
            ...headers,
        },
    });
};

const postJSON = (url, params = {}, prefix) => {
    let data = null;
    try {
        data = JSON.stringify(params);
    } catch (error) {
        data = JSON.stringify({});
    }
    return request(url, 'post', {
        data,
        contentType: 'application/json',
    }, prefix);
};

const postFile = (url, params = {}, prefix) => {
    let form = new window.FormData();
    Object.keys(params).forEach(item => {
        form.append(item, params[item]);
    });
    return request(url, 'post', {
        data: form,
        contentType: false,
        processData: false,
    }, prefix);
};

const getJSON = (url) => {
    return $.ajax({
        type: 'GET',
        url: `${url}?t=${new Date().getTime()}`,
        dataType: 'json',
    });
};
// window.open 下载文件
const downloadFileByWindowOpen = (url, query = {}) => {
    const params = new URLSearchParams({
        ...query,
    }).toString();
    console.log(`${url}?${params}`);
    window.open(`${url}?${params}`);
};

export {
    request,
    postJSON,
    postFile,
    getJSON,
    downloadFileByWindowOpen,
};
